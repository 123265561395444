import { Http } from './../../../../../utils';
export const apiGetAllCreatorsVideos = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
            searchOn: '',
            sortBy: settings.sortBy
        }
    }
    return Http.get('/clientapi/videos', options)
}

export const apiGetCreatorsVideos = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
            searchOn: 'creator',
            creatorId: settings.id,
            sortBy: settings.sortBy
        }
    }
    return Http.get('/clientapi/videos', options)
}

export const apiPatchUpdateVideoMetadata = (data, videoId) => {
    const options = {
        body: {
            'title': data.title,
            'description': data.description,
            'thumbnailSFW': data.getThumbnailSFW,
            'thumbnailNSFW': data.getThumbnailNSFW,
            'categories': data.categories,
            'performers': data.performers,
            'url': data.videoUrl,
            'key': data.key,
            'status': data.status,
        }
    }
    return Http.patch('/clientapi/video/' + videoId, options)
}

export const apiPatchUpdateVideoStatus = (status, videoId) => {
    const options = {
        body: {
            status
        }
    }
    return Http.patch('/clientapi/video/' + videoId, options)
}

export const apiGetSearchVideos = (settings, name) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
        }
    }
    return Http.get('/clientapi/video/search/' + settings.query, options)
}