import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { validateUserFromUrl } from '../../actions/account/loginAction';
import { USER_FETCH_DETAILS } from '../../actions/profile/user/userAction';
import { apiGetUserDetails } from '../../services/apis/account/userinfo';
import { BrowserStorage, StorageKey, Loader } from './../../utils';
import { Redirect } from 'react-router-dom';
export const Authorize = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { isUserAuthorized, errorLogin } = useSelector(state => state.login);
    const isSocialIntegration = BrowserStorage.get(StorageKey.isSocialIntegration);
    const getUserInfo = useCallback(() => {
        apiGetUserDetails()
            .then((user) => {
                const { success, data, error } = user;
                if (success) {
                    history.push('/dashboard')
                    dispatch({ type: USER_FETCH_DETAILS.SUCCESS, payload: { profileDetails: data } })
                }
                else {
                    history.push('/')
                    dispatch({ type: USER_FETCH_DETAILS.FAILURE, payload: { error } })
                }
            })
    }, [dispatch, history])

    const resolveUrl = useCallback(() => {
        Loader.show();
        if (isUserAuthorized) {
            if (isSocialIntegration) {
                history.push('/social-authorize')
            } else {
                getUserInfo();
            }
        } else {
            dispatch(validateUserFromUrl())
        }
    }, [isUserAuthorized, isSocialIntegration, history, getUserInfo, dispatch])

    useEffect(() => {
        resolveUrl();

    }, [resolveUrl])

    if (errorLogin) {
        BrowserStorage.clear();
        Loader.hide();
        return <Redirect to='/' />
    }
    return null;
}


