import { combineReducers } from 'redux';
import { LoginReducer } from './account/loginReducer';
import { UserReducer } from './profile/userReducer';
import { CommonReducer } from './account/commonReducer';
import { SignUpReducer } from './account/signupReducer';
import { CreatorsReducer } from './profile/admin/management/creatorsReducer';
import { ContentReducers } from './profile/user/contentmanager/contentReducer';
import { ContentsReducers } from './profile/admin/management/contentsReducer';
import { PerformerReducer } from './profile/admin/management/performerReducer';
import { CategoryReducer } from './profile/admin/management/categoryReducer';

export const RootReducer = combineReducers({
    login: LoginReducer,
    user: UserReducer,
    common: CommonReducer,
    signup: SignUpReducer,
    creator: CreatorsReducer,
    creatorContent: ContentReducers,
    adminContent: ContentsReducers,
    adminPerformers: PerformerReducer,
    adminCategories: CategoryReducer
});

