import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { emailVerification } from './../../actions/account/signupAction';
import { setAmplifyConfig } from '../../services/AWSCognitoService';
import { useSnackbar } from 'notistack';

export const LandingScreen = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { errorSignup, isEmailVerified } = useSelector(state => state.signup);
    const { enqueueSnackbar } = useSnackbar();

    const callback = useCallback(() => {
        if (!isEmailVerified && !errorSignup) {
            setAmplifyConfig();
            dispatch(emailVerification(new URLSearchParams(history.location.search).get('id')))
        } else if (errorSignup) {
            enqueueSnackbar('Token Expired!', {
                variant: 'error'
            })
            history.push('/');
        } else {
            history.push('/email-verification-success')
        }
    }, [dispatch, isEmailVerified, errorSignup, history, enqueueSnackbar]);

    useEffect(() => {
        callback();
    }, [callback])
    return null;
}