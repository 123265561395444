import { apiGetAllCreatorsVideos, apiGetCreatorsVideos, apiPatchUpdateVideoStatus, apiGetSearchVideos } from './../../../../services/apis/account/admin/management/content';

export const CONTENT_MANAGER_FETCH = {
    REQUEST: '@account/content-manager-admin-request',
    SUCCESS: '@account/content-manager-admin-success',
    FAILURE: '@account/content-manager-admin-failure'
}

export const CONTENT_MANAGER_STATUS_UPDATE = {
    REQUEST: '@account/content-admin-status-request',
    SUCCESS: '@account/content-admin-status-success',
    FAILURE: '@account/content-admin-status-failure'
}

export const fetchCreatorsAllVideos = (settings) => (dispatch) => {
    dispatch({ type: CONTENT_MANAGER_FETCH.REQUEST })
    apiGetAllCreatorsVideos(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                data.videos.map((x, index) => x.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CONTENT_MANAGER_FETCH.SUCCESS, payload: { content: data.videos, count: updatedCount } })
            } else {
                dispatch({ type: CONTENT_MANAGER_FETCH.FAILURE, payload: { error } })
            }
        })
}

export const fetchCreatorsVideos = (settings) => (dispatch) => {
    apiGetCreatorsVideos(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                data.videos.map((x, index) => x.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CONTENT_MANAGER_FETCH.SUCCESS, payload: { content: data.videos, count: updatedCount } })
            } else {
                dispatch({ type: CONTENT_MANAGER_FETCH.FAILURE, payload: { error } })
            }
        })
}

export const updateVideoStatus = (status, videoId) => (dispatch) => {
    apiPatchUpdateVideoStatus(status, videoId)
        .then(result => {
            const { success, error } = result;
            if (success)
                dispatch({ type: CONTENT_MANAGER_STATUS_UPDATE.SUCCESS, payload: { videoId } })
            else
                dispatch({ type: CONTENT_MANAGER_STATUS_UPDATE.FAILURE, payload: { error } })
        })
}

export const fetchFilteredVideos = settings => dispatch => {
    apiGetSearchVideos(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                data.videos.map((x, index) => x.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CONTENT_MANAGER_FETCH.SUCCESS, payload: { content: data.videos, count: updatedCount } })
            } else
                dispatch({ type: CONTENT_MANAGER_FETCH.FAILURE, payload: { error } })
        })
}