import { Http } from './../../../utils';
export const apiPatchForgetPassword = (email) => {
    const options = {
        dontUseAuth: false,
        body: {
            'email': email
        }
    }
    return Http.patch('/clientapi/creator/forgetpassword', options)
    //return Http.patch('/clientapi/creator/forgetpassword', false, options)

}

export const apiPatchResetPassword = (id, password) => {
    const options = {
        queryStringParameters: {
            'qt': id
        },
        body: {
            'password': password
        }
    }
    return Http.patch('/clientapi/creator/resetpassword', options)
    //return Http.patch('/clientapi/creator/resetpassword', false, options)
}

export const apiGetIsEmailUnique = (email) => {
    const options = {
        dontUseAuth: true,
        queryStringParameters: {
            email
        }
    }
    return Http.get('/clientapi/checkunique', options);
}