import auth0 from 'auth0-js';
import { CONFIG } from './../config';
import { konsole } from './../utils';

export class AuthMgmtService {
    constructor(token) {
        this.auth0 = new auth0.Management({
            domain: CONFIG.auth0.domain,
            token: token,
        });
    }
    changePassword = (userId, password) => new Promise((resolve, reject) => {
        this.auth0.patchUserAttributes(userId, { "password": password },
            (err, response) => {
                konsole.log(err, response)
                if (err) {
                    return reject(err);
                }
                return resolve(response);
            });
    })
}