import React, { useEffect, useState } from 'react';
import { TextField, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useSelector } from 'react-redux';
import { apiGetAllCategories } from './../../../../services/apis/videoinfo/category';
import { ListboxComponent } from './../ListBox';
import { styleOptionsList } from './../OptionsList.style';

export const CategoriesList = ({ categoryData, setFieldValue, error, helperText }) => {
    const classes = styleOptionsList();
    const [optionCategories, setCategories] = useState([]);
    const [optionAllCategories, setAllCategories] = useState([]);
    const [openCategory, setOpenCategory] = useState(false);
    const [selectedCategries, setSelectedCategories] = useState([]);
    const { user: { profileDetails } } = useSelector(state => state.user);
    const loading = Boolean(optionCategories.length === 0 && profileDetails.username)

    useEffect(() => {
        if (optionCategories.length > 0) {
            const categoryList = {};
            if (categoryData?.length) {
                categoryData.forEach(x => categoryList[x.name] = { name: x.name, categoryId: x.categoryId })
                setSelectedCategories(Object.keys(categoryList) ?? [])
            }
        }
    }, [categoryData, optionCategories])

    useEffect(() => {
        if (loading) {
            (() => {
                apiGetAllCategories()
                    .then(x => {
                        const { success, data } = x;
                        if (success) {
                            const objCategories = {};
                            Object.values(data.categories).forEach(x => objCategories[x.name] = { name: x.name, categoryId: x.categoryId })
                            setAllCategories(objCategories);
                            setCategories(Object.keys(data.categories));
                        }
                    })
            })();
        }
    }, [loading]);

    return (
        <Autocomplete
            multiple
            open={openCategory}
            onOpen={() => setOpenCategory(true)}
            onClose={() => setOpenCategory(false)}
            onChange={(event, value) => {
                setSelectedCategories([...value]); setFieldValue(Object.values(optionAllCategories).filter(z => value.includes(z.name)));
            }}
            getOptionSelected={(option, value) => option === value}
            getOptionLabel={(option) => option}
            options={optionCategories}
            disableListWrap
            classes={classes}
            ListboxComponent={ListboxComponent}
            loading={loading}
            size='small'
            value={selectedCategries}
            renderInput={(params) => (
                <TextField
                    {...params}
                    error={error}
                    helperText={helperText}
                    label="Categories"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
}