import { FORGET_PASSWORD } from '../../actions/account/commonAction';

const iniState = {
    errorCommon: null,
    forgetPasswordRequest: false,
}
export const CommonReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FORGET_PASSWORD.REQUEST:
            return { ...iniState };
        case FORGET_PASSWORD.SUCCESS:
            state.forgetPasswordRequest = true;
            return { ...state }
        case FORGET_PASSWORD.FAILURE:
            state.forgetPasswordRequest = false;
            state.errorCommon = payload.error;
            return { ...state };
        default: {
            return { ...iniState };
        }
    }
}