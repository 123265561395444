import { apiGetAllCreatorVideos, apiGetSearchVideos } from '../../../../services/apis/creator/contentmanager/content';
import { apiPatchDeleteVideoStatus } from '../../../../services/apis/videoinfo/proxyVideo';

export const CONTENT_MANAGER_FETCH = {
    REQUEST: '@account/content-manager-request',
    SUCCESS: '@account/content-manager-success',
    FAILURE: '@account/content-manager-failure'
}

export const CONTENT_UPDATE_DELETE_STATUS = {
    REQUEST: '@account/content-update-delete-request',
    SUCCESS: '@account/content-update-delete-success',
    FAILURE: '@account/content-update-delete-failure'
}

export const fetchCreatorAllVideos = (settings) => (dispatch) => {
    //dispatch({ type: CONTENT_MANAGER_FETCH.REQUEST })
    apiGetAllCreatorVideos(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                data.videos.map((x, index) => x.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CONTENT_MANAGER_FETCH.SUCCESS, payload: { content: data.videos, count: updatedCount } })
            } else {
                dispatch({ type: CONTENT_MANAGER_FETCH.FAILURE, payload: { error } })
            }
        })
}

export const fetchFilteredVideos = settings => dispatch => {
    apiGetSearchVideos(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                data.videos.map((x, index) => x.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CONTENT_MANAGER_FETCH.SUCCESS, payload: { content: data.videos, count: updatedCount } })
            } else
                dispatch({ type: CONTENT_MANAGER_FETCH.FAILURE, payload: { error } })
        })
}

export const updateDeleteVideoStatus = videoId => dispatch => {
    apiPatchDeleteVideoStatus(videoId)
        .then(result => {
            const { success, data, error } = result;
            if (success && data.message === 'Video has been created successfully.') {
                dispatch({ type: CONTENT_UPDATE_DELETE_STATUS.SUCCESS, payload: { videoId } })
            } else
                dispatch({ type: CONTENT_UPDATE_DELETE_STATUS.FAILURE, payload: { error } })
        })
}