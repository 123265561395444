import { LOGIN, REINITIALIZE } from '../../actions/account/loginAction';

const iniState = {
    user: {
        awsCredentials: null,
        bankInfo: null,
        profileDetails: {},
        metadata: null
    },
    errorLogin: null,
    isUserAuthorized: false,
    isSocialUserLoggedIn: false,
    reinitializePage: false,
    reinitializeError: ''
}
export const LoginReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOGIN.REQUEST:
            return { ...iniState };
        case LOGIN.SUCCESS:
            state.isUserAuthorized = true;
            state.user.profileDetails = payload.profileDetails;
            state.user.awsCredentials = payload.awsCredentials;
            return { ...state };
        case LOGIN.FAILURE:
            state.errorLogin = payload.error;
            return { ...state };
        case REINITIALIZE.SUCCESS:
            state.user.profileDetails = { ...state.user.profileDetails, ...payload.profileDetails };
            state.user.awsCredentials = payload.awsCredentials;
            state.isEmailVerified = payload.profileDetails.email_verified;
            state.reinitializePage = false;
            return { ...state };
        case REINITIALIZE.REQUEST:
            state.reinitializePage = true;
            return { ...state };
        case REINITIALIZE.FAILURE:
            state.reinitializeError = payload.error;
            return { ...state };
        default: {
            return iniState;
        }
    }
}