import { konsole } from './konsole';
export const resolveError = (error) => {
    konsole.log(error);
    let errorMessage;
    if (typeof (error) === 'object') {
        if (error.code === 'invalid_signup') {
            errorMessage = 'Already Registered!';
        } else if (error.code === 'invalid_grant' || error.code === 'access_denied') {
            errorMessage = error.description;
        }
        else {
            errorMessage = 'Something went wrong!!';
        }
    } else {
        errorMessage = error;
    }
    return errorMessage;
}