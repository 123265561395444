import React from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { Page } from '../../..';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { Separator } from '../../../core/Separator';
import { styleForgetPassword } from './ForgetPassword.style';
import { Line } from '../../..';
import { useSelector } from 'react-redux';

export const ForgetPasswordVerification = ({ className }) => {
    const classes = styleForgetPassword();
    const account = useSelector(state => state.common);
    if (!account.forgetPasswordRequest) {
        return <Redirect to='/' />
    }
    return (
        <Page
            className={classes.fpwdRoot}
            title="Forgot Password">
            <Container maxWidth='xs'>
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Forget Password"
                    className={classes.fpwdHeading}
                />
                <Line />
            </Container>
            <Container maxWidth='md' style={{ paddingTop: '20px' }}>

                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="We have sent an email with a reset password link to your email address. 
                        To reset your password, click the reset password link in the email.
                        If you do not receive a reset password email, check your spam folder." />

                <Box mt={6} className={classes.fpwdContinueBtn}
                >
                    <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        component={RouterLink}
                        to='/'
                    >
                        Back To Home
                    </Button>
                </Box>
            </Container>

        </Page >
    );
}
