import React from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { Page, Separator } from '../../..';
import { Link as RouterLink } from 'react-router-dom';
import { styleAccountVerification } from '../../emailverificaiton/AccountEmailVerification.style';
export const ResetPasswordSuccess = () => {
    const classes = styleAccountVerification();

    return (
        <Page
            className={classes.accCnfRoot}
            title="Reset Password"
        >
            <Container maxWidth="lg">
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Reset Password"
                    className={classes.accCnfHeading}
                />
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="Your password has been changed successfully. Please login to continue!" />

                <Box mt={6} className={classes.accCnfContinueBtn}
                >
                    <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        component={RouterLink}
                        to='/'
                    >
                        Continue to Site
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}
