import React, { lazy, Fragment, Suspense } from 'react';
import { Switch, Route } from 'react-router';

const get = name => mod => ({ default: mod[name] });

const routesConfig = [
    {
        exact: true,
        path: '/',
        component: lazy(() => import('./../components').then(get('SplashScreen')))
    },
    {
        exact: true,
        path: '/login',
        component: lazy(() => import('./../views/auth').then(get('LoginView')))
    },
    {
        exact: true,
        path: '/register',
        component: lazy(() => import('./../views/auth').then(get('RegisterView')))
    },
    {
        exact: true,
        path: '/accountemailverification',
        component: lazy(() => import('./../components').then(get('AccountEmailVerification')))
    },
    {
        exact: true,
        path: '/authorize',
        component: lazy(() => import('./../components').then(get('Authorize')))
    },
    {
        exact: true,
        path: '/social-authorize',
        component: lazy(() => import('./../components').then(get('SocialAuthorize')))
    },
    {
        path: '/app/management',
        gaurd: lazy(() => import('./../components').then(get('AuthGuard'))),
        layout: lazy(() => import('./../layouts/').then(get('DashBoardLayout'))),
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./../views/management/creators/creatorlist/CreatorList')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/creators',
                component: lazy(() => import('./../views/management/creators/creatorlist/CreatorList')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/creators/edit/:username',
                component: lazy(() => import('./../views/management/creators/creatoredit/CreatorEditView')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/content',
                component: lazy(() => import('./../views/management/contentmanager/contentlist/ContentList')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/content/edit/:videoId',
                component: lazy(() => import('./../views/management/contentmanager/contentedit/ContentEditView')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/content/:cognitoId',
                component: lazy(() => import('./../views/management/contentmanager/contentlist/ContentList')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/categories',
                component: lazy(() => import('./../views/management/categories/Categories')),
                props: {
                    rootPath: '/app/management',
                }
            },
            {
                exact: true,
                path: '/performers',
                component: lazy(() => import('./../views/management/performers/Performers')),
                props: {
                    rootPath: '/app/management',
                }
            }
        ]
    },
    {
        path: '/dashboard',
        gaurd: lazy(() => import('./../components').then(get('AuthGuard'))),
        layout: lazy(() => import('./../layouts/').then(get('DashBoardLayout'))),
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./../views/dashboard/DashBoard')),
                props: {
                    rootPath: '/dashboard',
                }
            },
            {
                exact: true,
                path: '/general',
                component: lazy(() => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/dashboard',
                }
            },
            {
                exact: true,
                path: '/payment',
                component: lazy((props) => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/dashboard',
                    currentTab: 'payment'
                }
            },
            {
                exact: true,
                path: '/security',
                component: lazy((props) => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/dashboard',
                    currentTab: 'security'
                }
            },
            {
                exact: true,
                path: '/upload-content',
                component: lazy((props) => import('./../views/profile/uploadcontent/UploadZone')),
            },
            {
                exact: true,
                path: '/launch-content',
                component: lazy((props) => import('../views/profile/contentmetadata/ContentView')),
            },
            {
                exact: true,
                path: '/content/edit/:videoId',
                component: lazy((props) => import('../views/profile/contentmanager/contentedit/ContentEditView')),
            },
            {
                exact: true,
                path: '/content-manager',
                component: lazy((props) => import('../views/profile/contentmanager/contentlist/ContentList')),
            },
            {
                exact: true,
                path: '/account-pending-approval',
                component: lazy(() => import('./../components').then(get('AccountPendingApproval')))
            }
        ]
    },
    {
        exact: true,
        path: '/email-verification',
        component: lazy(() => import('./../components').then(get('LandingScreen')))
    },
    {
        exact: true,
        path: '/email-verification-success',
        component: lazy(() => import('./../components').then(get('AccountEmailVerificationSuccess')))
    },
    {
        exact: true,
        path: '/terms-condition',
        component: lazy(() => import('./../components').then(get('TermsCondition')))
    },
    {
        path: '/user-details',
        layout: lazy(() => import('./../layouts').then(get('UserDetailsLayout'))),
        routes: [
            {
                exact: true,
                path: '/',
                component: lazy(() => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/user-details',
                }
            },
            {
                exact: true,
                path: '/general',
                component: lazy(() => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/user-details',
                }
            },
            {
                exact: true,
                path: '/security',
                component: lazy(() => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/user-details',
                    currentTab: 'security'
                }
            },
            {
                exact: true,
                path: '/payment',
                component: lazy(() => import('./../views/account/AccountInfo')),
                props: {
                    rootPath: '/user-details',
                    currentTab: 'payment'
                }
            }
        ]
    },
    {
        exact: true,
        path: '/forget-password',
        component: lazy(() => import('./../components').then(get('ForgetPassword')))
    },
    {
        exact: true,
        path: '/forget-password-verification',
        component: lazy(() => import('./../components').then(get('ForgetPasswordVerification')))
    },
    {
        exact: true,
        path: '/reset-password',
        component: lazy(() => import('./../components').then(get('ResetPassword')))
    },
    {
        exact: true,
        path: '/reset-password-success',
        component: lazy(() => import('./../components').then(get('ResetPasswordSuccess')))
    },

];
const renderRoutes = (routes) => (routes ? (
    <Suspense fallback={''}>
        <Switch>
            {routes.map((route, index) => {
                const Gaurd = route.gaurd || Fragment;
                const Layout = route.layout || Fragment;
                let Component = route.component;
                const componentProps = route.props || {};

                return (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={(props) => (
                            <Gaurd>
                                <Layout>
                                    {route.routes ?
                                        renderRoutes(route.routes.map(m => ({ ...m, path: route.path + m.path }))) :
                                        <Component props={componentProps} {...props} />
                                    }
                                </Layout>
                            </Gaurd>
                        )}
                    />
                );
            })}
        </Switch>
    </Suspense>
) : null);

export const AppRoute = () => {
    return renderRoutes(routesConfig);
}