import React from 'react';
import PersonIcon from '@material-ui/icons/Person';
import { Tooltip, IconButton, SvgIcon } from '@material-ui/core';
export const ProfileIdIcon = ({ size = 'small', handleClickOpen, ...rest }) => {
    return (
        <>
            <Tooltip title='Profile Photo ID' arrow>
                <IconButton onClick={handleClickOpen} {...rest}
                >
                    <SvgIcon fontSize={size}>
                        <PersonIcon />
                    </SvgIcon>
                </IconButton></Tooltip>
        </>
    );
}