import React, { useEffect } from 'react';
import { Player, BigPlayButton, ControlBar } from 'video-react';
import 'video-react/dist/video-react.css';
import { Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { stylePlayerControl } from './PlayerControl.style';
export const PlayerControl = ({ changePlayerHandler, content }) => {
    const classes = stylePlayerControl();
    const history = useHistory();
    let playerRef;
    useEffect(() => {
        if (playerRef)
            playerRef.subscribeToStateChange(updatePlayerSettings);
    }, [playerRef]);

    function updatePlayerSettings(setting) {
        playerRef = setting;
        changePlayerHandler(playerRef);
    }

    return (
        <Grid item
            lg={11} xs={12}
            className={classes.root}
        >
            <Player fluid={false} height={300}
                ref={_ref => playerRef = _ref}
            >
                <BigPlayButton position="center" />
                <source src={content?.url ?? new URLSearchParams(history.location.search).get('vl')}
                    type="video/mp4"
                />
                <ControlBar autoHide={true} />
            </Player>
        </Grid>
    );
};