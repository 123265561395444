import auth0 from 'auth0-js';
import { CONFIG } from '../config';

export class AuthTokenService {

    static auth0 = new auth0.Authentication({
        clientID: CONFIG.auth0Mgmt.clientID,
        domain: CONFIG.auth0.domain,
    });

    static getToken = () => new Promise((resolve, reject) => {
        this.auth0.oauthToken({
            [CONFIG.auth0Mgmt.grantType]: CONFIG.auth0Mgmt.grantTypeValue,
            [CONFIG.auth0Mgmt.clientID]: CONFIG.auth0Mgmt.clientIDValue,
            [CONFIG.auth0Mgmt.clientSecret]: CONFIG.auth0Mgmt.clientSecretValue,
            [CONFIG.auth0Mgmt.audience]: CONFIG.auth0Mgmt.audienceValue
        }, (err, response) => {
            if (err) {
                return reject(err);
            }
            return resolve(response);
        });
    });
}