import { USER_UPDATE_DETAILS, USER_FETCH_DETAILS, UPDATE_URL, CHANGE_PASSWORD }
    from '../../actions/profile/user/userAction';

const iniState = {
    user: {
        bankInfo: null,
        profileDetails: {},
        metadata: null
    },
    errorUser: null,
    profileUpdated: false,
    imageIdUrl: {},
    imageUrl: {},
    changedPasswordDone: false,
}
export const UserReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case USER_UPDATE_DETAILS.REQUEST:
            state.profileUpdated = false;
            return { ...state }
        case USER_UPDATE_DETAILS.SUCCESS:
            state.user.profileDetails = { ...state.user.profileDetails, ...payload.user };
            state.profileUpdated = true;
            return { ...state };
        case USER_UPDATE_DETAILS.FAILURE:
            state.errorUser = payload.error;
            return { ...state };

        case USER_FETCH_DETAILS.REQUEST:
            state.profileUpdated = false;
            return { ...state };
        case USER_FETCH_DETAILS.SUCCESS:
            state.user.profileDetails = { ...state.user.profileDetails, ...payload.profileDetails };
            return { ...state };
        case USER_FETCH_DETAILS.FAILURE:
            state.errorUser = payload.error;
            return { ...state };

        case UPDATE_URL.SUCCESS:
            if (payload.type === 'id') {
                state.imageUrl = payload.urls;
            } else if (payload.type === 'holdingid') {
                state.imageIdUrl = payload.urls;
            }
            return { ...state };

        case CHANGE_PASSWORD.REQUEST:
            state.changedPasswordDone = false;
            state.errorUser = '';
            return { ...state }
        case CHANGE_PASSWORD.SUCCESS:
            state.changedPasswordDone = true;
            return { ...state };
        case CHANGE_PASSWORD.FAILURE:
            state.errorUser = payload.error;
            return { ...state };
        default: {
            return iniState;
        }
    }
}