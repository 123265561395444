import { makeStyles } from '@material-ui/core';
export const styleTermsCondition = makeStyles((theme) => ({
    apaRoot: {
        backgroundColor: theme.palette.background.dark,
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        left: 0,
        position: 'fixed',
        //justifyContent: 'center',
        top: 0,
        paddingTop: '30px',
        display: 'flex',
        width: '100%'
    },
    apaContent: {
        padding: theme.spacing(3),
    },
}));
