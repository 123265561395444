import { SIGNUP, SIGNUP_SOCIAL, EMAIL_VERIFICATION } from './../../actions/account/signupAction';

const iniState = {
    errorSignup: null,
    isNewUser: false,
    isSocialUserLoggedIn: false,
    isEmailVerified: false,
    isSignupDone: false
}
export const SignUpReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SIGNUP.REQUEST:
            return { ...iniState };
        case SIGNUP.SUCCESS:
            state.isSignupDone = true;
            return { ...state };
        case SIGNUP.FAILURE:
            state.errorSignup = payload.error;
            return { ...state };
        case EMAIL_VERIFICATION.REQUEST:
            return { ...iniState };
        case EMAIL_VERIFICATION.SUCCESS:
            state.isEmailVerified = true;
            return { ...state };
        case EMAIL_VERIFICATION.FAILURE:
            state.isEmailVerified = false;
            state.errorSignup = payload.error;
            return { ...state };
        case SIGNUP_SOCIAL.FAILURE:
            state.isSocialUserLoggedIn = false;
            state.errorSignup = payload.error;
            return { ...state };
        case SIGNUP_SOCIAL.REQUEST:
            state.isSocialUserLoggedIn = false;
            return { ...state };
        case SIGNUP_SOCIAL.SUCCESS:
            state.isSocialUserLoggedIn = true;
            return { ...state }
        default: {
            return { ...iniState };
        }
    }
}