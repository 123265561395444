import { Http } from '../../../utils';
export const apiPostSignup = (email, authId, socialId = '', socialType = '') => {
    const options = {
        body: {
            email,
            socialType,
            socialId,
            authId
        }
    }
    return Http.post('/clientapi/signup', options)
}

export const apiPatchEmailVerification = (id) => {
    const options = {
        dontUseAuth: true,
        queryStringParameters: {
            qt: id
        },
    }
    return Http.patch('/clientapi/creator/verification', options)
}