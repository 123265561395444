import { CONTENT_MANAGER_FETCH, CONTENT_UPDATE_DELETE_STATUS } from './../../../../actions/profile/user/contentmanager/contentAction';

const iniState = {
    errorContent: null,
    contents: [],
    count: 0,
    contentId: null
}

export const ContentReducers = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CONTENT_MANAGER_FETCH.REQUEST:
            return { ...iniState };
        case CONTENT_MANAGER_FETCH.SUCCESS:
            const { content, count } = payload;
            state.contents = content;
            state.errorContent = null;
            if (content.length === 0) {
                state.count = 0;
            }
            if (count) {
                state.count = count;
            }
            return { ...state }
        case CONTENT_MANAGER_FETCH.FAILURE:
            state.errorContent = payload.error;
            return { ...state };
        case CONTENT_UPDATE_DELETE_STATUS.SUCCESS:
            state.contentId = payload.videoId;
            state.errorContent = null;
            return { ...state }
        default:
            return { ...iniState };
    }
}
