import { useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
const DashBoard = () => {
    const { user: { profileDetails } } = useSelector(state => state.user);
    const history = useHistory();
    const roleid = profileDetails?.roleId;
    const status = profileDetails?.status;
    const username = profileDetails?.username;
    const redirect = useCallback(() => {
        if (roleid === 'admin') {
            history.push('/app/management/creators')
        } else {
            if (status === 'active') {
                history.push('/dashboard/upload-content')
            }
            else if (username && (status === 'pending' || status === 'inactive')) {
                history.push('/dashboard/account-pending-approval')
            } else {
                history.push('/dashboard/general')
            }
        }
    }, [roleid, history, username, status])

    useEffect(() => {
        redirect();
    }, [redirect])
    return null;
}

export default DashBoard;