import { CREATORS_FETCH, CREATORS_UPDATE_STATUS, CREATOR_UPDATE_PROFILE, CREATOR_FETCH_PROFILE } from './../../../../actions/profile/admin/management/creatorsAction';

const iniState = {
    errorCreator: null,
    creators: [],
    count: 0,
    creatorUpdated: false,
    creatorStatus: null,
    creator: {}
}
export const CreatorsReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CREATORS_FETCH.REQUEST:
            //state.creatorUpdated = false;
            return { ...iniState };
        case CREATORS_FETCH.SUCCESS:
            const { creators, count } = payload;
            state.creators = creators;
            state.errorCreator = null;
            state.creatorUpdated = false;
            if (creators.length === 0) {
                state.count = 0;
            }
            if (count) {
                state.count = count;
            }
            return { ...state }
        case CREATORS_FETCH.FAILURE:
            state.errorCreator = payload.error;
            state.creators = [];
            return { ...state }

        case CREATOR_UPDATE_PROFILE.SUCCESS:
            state.creatorUpdated = true;
            return { ...state };
        case CREATOR_UPDATE_PROFILE.FAILURE:
            state.errorCreator = payload.error;
            return { ...state };

        case CREATORS_UPDATE_STATUS.REQUEST:
            state.creatorUpdated = false;
            return { ...state };
        case CREATORS_UPDATE_STATUS.SUCCESS:
            //state.creatorUpdated = true;
            const found = state.creators.find(z => z.username === payload.username || z.email === payload.username)
            const notFound = state.creators.filter(z => z.username !== payload.username || z.email === payload.username);
            if (found) {
                state.creatorStatus = payload.username;
                const updatedCreator = { ...found };
                updatedCreator.status = payload.status
                state.creators = [...notFound, updatedCreator]
            } else {
                state.creators = [...notFound]
            }
            return { ...state };
        case CREATORS_UPDATE_STATUS.FAILURE:
            state.errorCreator = payload.error;
            state.creatorUpdated = false;
            return { ...state };

        case CREATOR_FETCH_PROFILE.SUCCESS:
            state.creator = payload.creator;
            state.creatorUpdated = false;
            return { ...state }
        default: {
            return iniState;
        }
    }
}