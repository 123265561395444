import { makeStyles } from '@material-ui/core';
export const styleSocialAuthButtons = makeStyles((theme) => ({
    btnTwitter: {
        backgroundColor: 'rgb(90, 164, 235)',
        width: '100%',
    },

    btnGoogle: {
        backgroundColor: 'rgb(203, 63, 34)',
        width: '100%',
    },
    googleIcon: {
        height: '18px'
    },
    separator: {
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
        },
        [theme.breakpoints.up('sm')]: {
            display: 'none',
        },
    },
    extraSeparator: {
        display: 'flex',
        justifyContent: 'center',
    },

}));