import { FETCH_CATEGORY, CREATE_CATEGORY, UPDATE_CATEGORY } from './../../../../actions/profile/admin/management/categoryAction';
const iniState = {
    categoryList: [],
    errorCategory: null,
    count: 0,
    categoryUpdated: {},
    errorCategoryUpdated: null,
    categoryCreated: null,
    errorCategoryCreated: null
}

export const CategoryReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_CATEGORY.REQUEST:
            return { ...iniState }
        case FETCH_CATEGORY.SUCCESS:
            const { categories } = payload;
            state.categoryList = categories;
            state.errorCategory = null;
            state.errorCategoryUpdated = null;
            state.errorCategoryCreated = null;
            return { ...state };
        case FETCH_CATEGORY.FAILURE:
            state.categoryList = [];
            state.errorCategory = payload.error;
            return { ...state }
        case UPDATE_CATEGORY.SUCCESS:
            state.categoryUpdated = payload.category;
            state.errorCategoryUpdated = null;
            return { ...state };
        case UPDATE_CATEGORY.FAILURE:
            state.categoryUpdated = null;
            state.errorCategroyUpdated = payload.error;
            return { ...state };
        case CREATE_CATEGORY.SUCCESS:
            state.categoryCreated = payload.categoryName;
            state.errorCategoryCreated = null;
            return { ...state };
        case CREATE_CATEGORY.FAILURE:
            state.categoryCreated = null;
            state.errorCategoryCreated = payload.error;
            return { ...state };
        default:
            return { ...iniState }
    }
}