import React, { useEffect, useState, useCallback } from 'react';
import { Container, Box, Button, Grid, TextField, CardContent } from '@material-ui/core';
import { Page } from '../../..';
import { useHistory } from 'react-router-dom';
import { Separator } from '../../../core/Separator';
import { styleForgetPassword } from './ForgetPassword.style';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Line } from '../../..';
import { forgetPassword } from '../../../../actions/account/commonAction';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

export const ForgetPassword = ({ className }) => {
    const classes = styleForgetPassword();
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const [isSubmit, setSubmit] = useState(false);
    const { errorCommon, forgetPasswordRequest } = useSelector(state => state.common);

    const callback = useCallback(() => {
        if (errorCommon) {
            enqueueSnackbar(errorCommon, {
                variant: 'error'
            });
            setSubmit(false);
        } else if (forgetPasswordRequest) {
            enqueueSnackbar('Email sent successfully', {
                variant: 'success'
            });
            history.push('/forget-password-verification')
        }
    }, [errorCommon, forgetPasswordRequest, history, enqueueSnackbar])

    useEffect(() => {
        callback()
    }, [callback])
    return (
        <Page
            className={classes.fpwdRoot}
            title="Forgot Password">
            <Container maxWidth='xs'>
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Forget Password"
                    className={classes.fpwdHeading}
                />
                <Line />
            </Container>
            <Container maxWidth='xs'>

                <Grid container>
                    <Grid item style={{ width: '100%' }}>
                        <Formik
                            initialValues={{
                                email: ''
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    email: Yup.string().email('Email should be valid and unique').max(255).required('Email is required'),
                                })
                            }
                            onSubmit={(values, { setErrors, setStatus, setSubmitting }) => {
                                setSubmit(true)
                                dispatch(forgetPassword(values.email))
                            }}
                        >
                            {({
                                errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values
                            }) =>
                                <form onSubmit={handleSubmit}>
                                    <CardContent>
                                        <Grid container>
                                            <TextField
                                                fullWidth
                                                error={Boolean(touched.email && (errors.email || errors.errors || errorCommon))}
                                                helperText={touched.email && (errors.email || errors.errors || errorCommon)}
                                                name='email'
                                                type='email'
                                                label='Email Address'
                                                size='small'
                                                variant='outlined'
                                                value={values.email}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                required
                                                inputProps={{ className: classes.fpwdInput }}
                                            />
                                        </Grid>
                                    </CardContent>
                                    <Box
                                        mt={2}
                                        className={classes.fpwdSubmitBtn}
                                    >
                                        <Button
                                            color="secondary"
                                            size="small"
                                            disabled={isSubmit}
                                            variant="outlined"
                                            type='submit'
                                        >
                                            Submit
                                             </Button>
                                    </Box>
                                </form>
                            }
                        </Formik>
                    </Grid>
                </Grid>

            </Container>
        </Page >
    );
}
