import React from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { Page, Separator } from '../..';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { styleAccountVerification } from './AccountEmailVerification.style';
import { useSelector } from 'react-redux';
export const AccountEmailVerificationSuccess = () => {
    const classes = styleAccountVerification();
    const { isEmailVerified } = useSelector(state => state.signup);
    if (!isEmailVerified) {
        return <Redirect to='/' />
    }
    return (
        <Page
            className={classes.accCnfRoot}
            title="Account Email Verification"
        >
            <Container maxWidth="lg">
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Account Email Verification"
                    className={classes.accCnfHeading}
                />
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="Your email has been verified successfully. Please login to continue!" />

                <Box mt={6} className={classes.accCnfContinueBtn}
                >
                    <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        component={RouterLink}
                        to='/'
                    >
                        Continue to Site
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}
