import React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BrowserStorage, Loader, StorageKey } from './../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { reinitialize } from '../../actions/account/loginAction';
import DashBoard from '../../views/dashboard/DashBoard';

export const SplashScreen = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { user: { profileDetails } } = useSelector(state => state.user)
    const { reinitializeError } = useSelector(state => state.login)
    const accessToken = BrowserStorage.get(StorageKey.accessToken);
    const idToken = BrowserStorage.get(StorageKey.idToken);

    useEffect(() => {
        if (accessToken && idToken && !reinitializeError) {
            Loader.show();
            dispatch(reinitialize(accessToken, idToken))
        }
        else {
            BrowserStorage.clear();
            history.push('/login')
            Loader.hide();
        }
    }, [accessToken, idToken, dispatch, history, reinitializeError])

    return <>
        {profileDetails?.username && <DashBoard />}
    </>;
}