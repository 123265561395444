import { Http } from './../../../utils';

export const apiGetUserDetails = () => Http.get('/clientapi/creator');

export const apiGetSignedUrl = (username, id) => {
    const options = {
        queryStringParameters: {
            username,
            'type': id
        }
    }
    return Http.get('/clientapi/image', options);
}

export const apiGetSignedUploadUrl = (id) => {
    const options = {
        queryStringParameters: {
            'type': id
        }
    }
    return Http.get('/clientapi/upload', options)
}

export const apiGetIsUsernameUnique = (username) => {
    const options = {
        dontUseAuth: true,
        queryStringParameters: {
            'username': username
        }
    }
    return Http.get('/clientapi/checkunique', options)
}

export const apiPatchUserDetails = (userDetails) => {
    const options = {
        body: {
            ...userDetails
        }
    }
    return Http.patch('/clientapi/creator', options)
}

