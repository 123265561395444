import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { Button, withStyles, makeStyles } from '@material-ui/core';

const styleThumbnailDialogBox = makeStyles(theme => ({
    dialogBox: {
        display: 'flex', justifyContent: 'center'
    },
    imgDialogBox: {
        width: '100%',
        height: '99%'
    },
}));

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        padding: theme.spacing(0, 2),
    },
}))(MuiDialogActions);

const DialogTitle = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2, 1, 2),
    },
}))(MuiDialogTitle);

const Dialog = withStyles((theme) => ({
    root: {
        padding: theme.spacing(1, 2, 1, 2),
        width: '100%',
        height: '100%'
    },
    paperWidthSm: {
        maxWidth: '90vw',
    },
    paperScrollPaper: {
        maxHeight: '98%'
    },
    scrollPaper: {
        justifyContent: 'space-between',
        flexFlow: 'column'
    },
    paper: {
        margin: 0,
        position: 'inherit'
    }
}))(MuiDialog);

export const ThumbnailDialogBox = ({ handleClose, title, open, url, setFieldValue }) => {
    const classes = styleThumbnailDialogBox();
    return (
        <Dialog open={open} onClose={e => handleClose('cancel', setFieldValue)}>
            <DialogTitle className={classes.dialogBox} >{title}</DialogTitle>
            <DialogContent>
                <img className={classes.imgDialogBox} src={url} alt='' />
            </DialogContent>
            <DialogActions>
                <Button onClick={e => handleClose('cancel', setFieldValue)} color="primary">Cancel</Button>
                <Button onClick={e => handleClose('confirm', setFieldValue)} color="primary" autoFocus>Confirm</Button>
            </DialogActions>
        </Dialog>
    )
}