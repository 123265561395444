import { makeStyles } from '@material-ui/core';
export const styleAccountVerification = makeStyles((theme) => ({
    accCnfRoot: {
        backgroundColor: theme.palette.background.dark,
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        left: 0,
        position: 'fixed',
        justifyContent: 'center',
        top: 0,
        display: 'flex',
        width: '100%'
    },
    accCnfHeading: {
        padding: theme.spacing(3),
    },
    accCnfContinueBtn: {
        justifyContent: 'center',
        textAlign: 'center'
    }
}));