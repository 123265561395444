import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { CardContent, Card, TextField, Grid, FormHelperText, Box, Button, Container } from '@material-ui/core';
import { Line } from '../../..';
import { useDispatch } from 'react-redux';
import { resetPassword } from '../../../../actions/account/commonAction';
import { useHistory } from 'react-router-dom';
import { styleResetPassword } from './ResetPassword.style';
import { Page } from '../../../core/Page';
import { Separator } from '../../../core/Separator';

export const ResetPassword = ({ ...rest }) => {
    const classes = styleResetPassword();
    const dispatch = useDispatch();
    const history = useHistory();
    return (<>
        <Page
            className={classes.fpwdRoot}
            title="Reset Password">
            <Container maxWidth='xs'>
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Reset Password"
                    className={classes.fpwdHeading}
                />
                {/* <Line margin={3} /> */}
            </Container>
            <Container maxWidth="sm">
                <Formik
                    initialValues={{
                        passwordNew: '',
                        passwordConfirm: ''
                    }}
                    validationSchema={Yup.object().shape({
                        passwordNew: Yup.string().min(8).matches(
                            /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])/,
                            'Password should be 8 characters long containing: 1 lower case, 1 upper case, 1 numeric, 1 special character'
                        ).required('New Password is required'),
                        passwordConfirm: Yup.string().oneOf([Yup.ref('passwordNew'), null], 'Password must match')
                            .required('Confirm Password is required'),
                    })}
                    onSubmit={async (values, { resetForm, setErrors, setStatus, setSubmitting }) => {
                        dispatch(resetPassword(new URLSearchParams(history.location.search).get('id'), values.passwordNew))
                            .then(success => history.push('/reset-password-success'))
                            .catch(error => {
                                setStatus({ success: false });
                                setErrors({ errors: error.message });
                                setSubmitting(false);
                            })
                    }}
                >
                    {({
                        errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values
                    }) =>
                        <form onSubmit={handleSubmit}>
                            <Card >
                                {/* <CardHeader title="Change Password" /> */}
                                <Container maxWidth='xs'>
                                    <Line margin={3} />
                                </Container>
                                <CardContent>
                                    <Grid container spacing={4} >
                                        <Grid item
                                            sm={8}
                                            xs={12}>
                                            <TextField
                                                fullWidth
                                                name='passwordNew'
                                                label='New Password'
                                                type='password'
                                                variant='outlined'
                                                size='small'
                                                value={values.passwordNew}
                                                error={Boolean(touched.passwordNew && errors.passwordNew)}
                                                helperText={touched.passwordNew && errors.passwordNew}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                //required
                                                className={classes.secTxtField}
                                            />
                                        </Grid>
                                        <Grid item
                                            sm={8}
                                            xs={12}>
                                            <TextField
                                                error={Boolean(touched.passwordConfirm && errors.passwordConfirm)}
                                                helperText={touched.passwordConfirm && errors.passwordConfirm}
                                                fullWidth
                                                //required
                                                name='passwordConfirm'
                                                label='Confirm Password'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.passwordConfirm}
                                                type='password'
                                                variant='outlined'
                                                size='small'
                                                className={classes.secTxtField}
                                            />
                                        </Grid>
                                    </Grid>
                                    {errors.submit && (
                                        <Box>
                                            <FormHelperText error>
                                                {errors.submit}
                                            </FormHelperText>
                                        </Box>
                                    )}
                                </CardContent>
                                <Container maxWidth='xs'>
                                    <Line margin={2} />
                                </Container>
                                <Box display='flex' justifyContent='center' p={2}>
                                    <Button variant='outlined' disabled={isSubmitting} color='secondary' type='submit' >
                                        Reset Password
                            </Button>
                                </Box>
                            </Card>
                        </form>
                    }
                </Formik>
            </Container>
        </Page>
    </>);
}