import React, { useState, useContext } from 'react';
import Uploady, { useItemProgressListener, useItemFinishListener, UploadyContext, useItemErrorListener } from "@rpldy/uploady";
import { Button } from '@material-ui/core';
import { apiGetThumbnailSignedUrl } from './../../../../services/apis/videoinfo/proxyVideo';
import { konsole, Loader } from './../../../../utils';

const UploadProgress = ({ onFile, onError }) => {
    const [uploads, setUploads] = useState({});
    const progressData = useItemProgressListener();
    const [state, setState] = useState('error');

    useItemFinishListener((item) => {
        setState(item.state);
        onFile && onFile(item.id);
    });

    useItemErrorListener((item) => {
        Loader.hide();
        onError('Error while uploading....');
        setState(item.state);
    })

    if (progressData && progressData.completed < 20 && state !== 'finished') {
        Loader.show();
    }

    if (progressData && progressData.completed) {
        const upload = uploads[progressData.id] ||
            { name: progressData.url || progressData.file.name, progress: [0] };

        if (!~upload.progress.indexOf(progressData.completed)) {
            upload.progress.push(progressData.completed);

            setUploads({
                [progressData.id]: upload,
            });
            setState('error')
        }
    }
    return <></>
};

const UploadButton = ({ idType, caption, isError, fileFilter, errMsg, setFiles, thumbnailUrl }) => {
    const uploady = useContext(UploadyContext);
    let url = thumbnailUrl;

    const onClick = async () => {
        try {
            if (!url) {
                await apiGetThumbnailSignedUrl(idType)
                    .then((out) => {
                        const { success, data } = out;
                        if (success) {
                            url = data.uploadUrl;
                            setFiles(data);
                        }
                    })
            }

            uploady.showFileUpload({
                destination: {
                    url,
                    method: 'PUT',
                    headers: {
                        "Content-Type": '',
                    },
                },
                sendWithFormData: false,
                fileFilter: (e) => fileFilter(e)
            });

        } catch (error) {
            Loader.hide();
            konsole.error(error, error.response);
            throw error;
        }
    }

    return <>
        <Button type='button'
            onClick={onClick}
            style={{
                // color: isError ? 'red' : '#a67dff'
                color: '#a67dff'
            }}
        >
            {caption}</Button>
        <div>
            <span style={{ color: isError ? 'red' : '#a67dff' }}>{isError ? errMsg : ''}</span>
        </div>
    </>
}

export const UploadThumbnail = ({ caption, idType, selectedFile, fileFilter, isError, errMsg, onError, setFiles, thumbnailUrl, vale }) => {
    return (
        <Uploady>
            <UploadButton idType={idType}
                caption={caption}
                isError={isError}
                errMsg={errMsg}
                fileFilter={e => fileFilter(e)}
                setFiles={e => setFiles(e)}
                thumbnailUrl={thumbnailUrl}
            />
            <UploadProgress
                onFile={e => {
                    Loader.hide();
                    selectedFile(e);
                }}
                isError={isError}
                onError={onError}
            />
        </Uploady>
    );
}