export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const SocialIntegrationTypes = {
  GOOGLE: 'GOOGLE',
  TWITTER: 'TWITTER'
}

export const imageType = {
  image: 'image',
  holdingIdImage: 'holdingIdImage'
}

export const videoProgress = {
  Pending: -1,
  Started: 0,
  Finished: 1,
  Failed: 2,
  Cancelled: 3,
  Uploaded: 4,
  Submitted: 5
}

export const tabs = [
  {
    value: 'active',
    label: 'Approved'
  },
  {
    value: 'pending',
    label: 'Pending'
  },
  {
    value: 'inactive',
    label: 'Rejected'
  },
];

export const sortOptions = [
  {
    value: 'desc',
    label: 'Last update (newest first)'
  },
  {
    value: 'asc',
    label: 'Last update (oldest first)'
  },
];

export const filterStatus = [
  {
    value: 'all',
    label: 'All'
  }, {
    value: 'active',
    label: 'Approved'
  }, {
    value: 'pending',
    label: 'Pending',
  }, {
    value: 'inactive',
    label: 'Rejected'
  }]

export const genderOptions = [
  {
    label: 'Male',
    value: 'male'
  }, {
    label: 'Female',
    value: 'female'
  }, {
    label: 'Shemale',
    value: 'shemale'
  }]

export const routeTypes={
 pending:'pending',
 general:'general' ,
}