import { Http } from '../../../../utils';
export const apiGetAllCreatorVideos = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
            searchOn: 'creator',
            sortBy: settings.sortBy
        }
    }
    return Http.get('/clientapi/videos', options)
}

export const apiGetSearchVideos = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
            //sortBy: settings.sortBy
        }
    }
    return Http.get('/clientapi/video/search/' + settings.query, options)
}
