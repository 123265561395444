import React from 'react';
import { Box, Typography } from '@material-ui/core';
export const Separator = ({ text, color, variant, ...rest }) => {
    return (
        <Box>
            <Typography
                color={color}
                variant={variant}
                {...rest}
            > {text}
            </Typography>
        </Box>
    );
}