import React from 'react';
import { Container } from '@material-ui/core';
import { Page, Separator } from '../..';
import { styleAccountPendingAccount } from './AccountPendingApproval.style';
export const AccountPendingApproval = () => {
    const classes = styleAccountPendingAccount();
    return (
        <Page
            className={classes.apaRoot}
            title="Account Pending Approval"
        >
            <Container maxWidth="lg">
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Account Pending Approval"
                    className={classes.apaContent}
                />
                <Separator
                    color="textSecondary"
                    variant="subtitle1"
                    align="center"
                    text="Thank you for applying, your account is now pending review and approval." />
                <Separator
                    color="textSecondary"
                    variant="subtitle1"
                    align="center"
                    text="You will get notified by email once approved or if we require more information." />
            </Container>
        </Page>
    );
}
