import {
    apiGetCreatorsProfile, apiPatchUpdateCreatorProfileStatus, apiGetFilteredCreators,
    apiPatchUpdateCreatorProfile, apiGetCreatorProfile
} from '../../../../services/apis/account/admin/management/creator';

export const CREATORS_FETCH = {
    REQUEST: '@account/creator-fetch-request',
    SUCCESS: '@account/creator-fetch-success',
    FAILURE: '@account/creator-fetch-failure',
}

export const CREATOR_UPDATE_PROFILE = {
    REQUEST: '@account/creator-update-profile-request',
    SUCCESS: '@account/creator-update-profile-success',
    FAILURE: '@account/creator-update-profile-failure',
}

export const CREATORS_UPDATE_STATUS = {
    REQUEST: '@account/creator-update-status-request',
    SUCCESS: '@account/creator-update-status-success',
    FAILURE: '@account/creator-update-status-failure',
}

export const CREATOR_FETCH_PROFILE = {
    SUCCESS: '@account/creator-fetch-profile-success',
    FAILURE: '@account/creator-fetch-profile-failure',
}
export const fetchAllCreators = (settings = {}) => (dispatch) =>{
    dispatch({type:CREATORS_FETCH.REQUEST})
    apiGetCreatorsProfile(settings)
        .then((users) => {
            const { success, data, error } = users;
            if (success) {
                data.profiles.map((z, index) => z.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CREATORS_FETCH.SUCCESS, payload: { creators: data.profiles, count: updatedCount, status: settings.status } })
            } else {
                dispatch({ type: CREATORS_FETCH.FAILURE, payload: { error } })
            }
        })
    }

export const updateCreatorStatus = ({ username, status }) => (dispatch) => {
    apiPatchUpdateCreatorProfileStatus(username, status)
        .then((users) => {
            const { success, error } = users;
            if (success) {
                dispatch({ type: CREATORS_UPDATE_STATUS.SUCCESS, payload: { status, username } })
            } else {
                dispatch({ type: CREATORS_UPDATE_STATUS.FAILURE, payload: { error } })
            }
        })
}

export const fetchFilteredCreators = (settings = {}) => (dispatch) => {
    apiGetFilteredCreators(settings)
        .then((users) => {
            const { success, data, error } = users;
            if (success) {
                data.profiles.map((z, index) => z.id = index + 1)
                const updatedCount = data.count ?? 0;
                dispatch({ type: CREATORS_FETCH.SUCCESS, payload: { creators: data.profiles, count: updatedCount, status: settings.status } })
            } else {
                dispatch({ type: CREATORS_FETCH.FAILURE, payload: { error } })
            }
        })
}

export const updateCreatorProfile = (userDetails, username) => (dispatch) => {
    apiPatchUpdateCreatorProfile(userDetails, username)
        .then((users) => {
            const { success, error } = users;
            if (success) {
                dispatch({ type: CREATOR_UPDATE_PROFILE.SUCCESS })
            } else {
                dispatch({ type: CREATOR_UPDATE_PROFILE.FAILURE, payload: { error } })
            }
        })
}

export const fetchCreatorProfile = (username) => (dispatch) => {
    apiGetCreatorProfile(username)
        .then((users) => {
            const { success, data, error } = users;
            if (success) {
                dispatch({ type: CREATOR_FETCH_PROFILE.SUCCESS, payload: { creator: data } })
            } else {
                dispatch({ type: CREATOR_FETCH_PROFILE.FAILURE, payload: { error } })
            }
        })
}