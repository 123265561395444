import { makeStyles } from '@material-ui/core';
export const stylePlayerControl = makeStyles(theme => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        paddingLeft: '10px',
        paddingRight: '10px',
        paddingTop: '20px',
        paddingBottom: '20px'
    }
}))
