import { apiGetPerformers, apiPatchUpdatePerformer, apiPostCreatePerformer, apiGetFilterPerformers } from './../../../../services/apis/videoinfo/performer';
export const FETCH_PERFORMERS = {
    REQUEST: '@account/admin-fetch-performers-request',
    SUCCESS: '@account/admin-fetch-performers-success',
    FAILURE: '@account/admin-fetch-performers-failure',
}

export const UPDATE_PERFORMERS = {
    REQUEST: '@account/admin-update-performers-request',
    SUCCESS: '@account/admin-update-performers-success',
    FAILURE: '@account/admin-update-performers-failure',
}

export const CREATE_PERFORMER = {
    REQUEST: '@account/admin-create-performers-request',
    SUCCESS: '@account/admin-create-performers-success',
    FAILURE: '@account/admin-create-performers-failure',
}

export const fetchPerformers = (settings) => (dispatch) =>
    apiGetPerformers(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success && data.performers.length > 0) {
                data.performers.map((z, index) => z.id = index + 1);
                const updatedCount = data.count ?? 0;
                dispatch({ type: FETCH_PERFORMERS.SUCCESS, payload: { performers: data.performers, count: updatedCount } })
            } else {
                dispatch({ type: FETCH_PERFORMERS.FAILURE, payload: { error } })
            }
        })

export const fetchFilteredPerformers = (settings) => (dispatch) =>
    apiGetFilterPerformers(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success && data.performers.length > 0) {
                data.performers.map((z, index) => z.id = index + 1);
                const updatedCount = data.count ?? 0;
                dispatch({ type: FETCH_PERFORMERS.SUCCESS, payload: { performers: data.performers, count: updatedCount } })
            } else {
                dispatch({ type: FETCH_PERFORMERS.FAILURE, payload: { error } })
            }
        })


export const updatePerformer = settings => (dispatch) =>
    apiPatchUpdatePerformer(settings)
        .then(result => {
            const { success, error } = result;
            if (success) {
                dispatch({ type: UPDATE_PERFORMERS.SUCCESS, payload: { performerId: settings.performerId } })
            } else {
                dispatch({ type: UPDATE_PERFORMERS.FAILURE, payload: { error } })
            }
        })

export const createPerformer = settings => dispatch =>
    apiPostCreatePerformer(settings)
        .then(result => {
            const { success, data, error } = result;
            if (success) {
                dispatch({ type: CREATE_PERFORMER.SUCCESS, payload: { performerId: data.performerId } })
            } else {
                dispatch({ type: CREATE_PERFORMER.FAILURE, payload: { error } })
            }
        })