import { apiGetIsEmailUnique, apiPatchForgetPassword, apiPatchResetPassword } from './../../services/apis/account/common';

export const FORGET_PASSWORD = {
    REQUEST: '@account/forget-password-request',
    SUCCESS: '@account/forget-password-success',
    FAILURE: '@account/forget-password-failure',
}

export const forgetPassword = (email) => (dispatch) => {
    dispatch({ type: FORGET_PASSWORD.REQUEST })
    apiGetIsEmailUnique(email)
        .then((user) => {
            const { success, data } = user;
            if (success && data.emailVerified && data.registrationSource === 'native') {
                apiPatchForgetPassword(email)
                    .then((out) => {
                        const { success } = out;
                        if (success) {
                            dispatch({ type: FORGET_PASSWORD.SUCCESS })
                        } else {
                            dispatch({ type: FORGET_PASSWORD.FAILURE, payload: { error: `Something went wrong!!` } })
                        }
                    })
            } else {
                dispatch({ type: FORGET_PASSWORD.FAILURE, payload: { error: `Email doesn't exist` } })
            }
        })
}


export const resetPassword = (token, password) => () => new Promise((resolve, reject) =>
    apiPatchResetPassword(token, password)
        .then(out => resolve())
        .catch(error => reject(error))
)

