import { AuthService } from './../../services/AuthService';
import { resolveError, konsole, Loader } from './../../utils';
import { setAWSCognitoCredentials } from './../../services/AWSCognitoService';
import { getUserDetails } from './../profile/user/userAction';
export const LOGIN = {
    REQUEST: '@account/login-request',
    SUCCESS: '@account/login-success',
    FAILURE: '@account/login-failure',
}
export const REINITIALIZE = {
    REQUEST: '@account/reinit-request',
    SUCCESS: '@account/reinit-success',
    FAILURE: '@account/reinit-failure',
}

export const userLogin = (email = '', password = '') => (dispatch) => {
    dispatch({ type: LOGIN.REQUEST })
    Loader.show();
    AuthService.checkUserCrendentials(email, password)
        .then((out) => AuthService.getUserInfo(out.accessToken)
            .then((user) => {
                if (user.email_verified) {
                    AuthService.loginWithCredentials(email, password)
                } else {
                    Loader.hide();
                    dispatch({ type: LOGIN.FAILURE, payload: { error: 'Please verify your email!!' } });
                }
            }))
        .catch(error => {
            Loader.hide();
            dispatch({ type: LOGIN.FAILURE, payload: { error: resolveError(error) } });
        })
}

export const logoutUser = () => (dispatch) => {
    return AuthService.logoutUser()
}

export const validateUserFromUrl = () => (dispatch) =>
    AuthService.getUrlInfo()
        .then(result => setAWSCognitoCredentials(result.idToken, true)
            .then(out => {
                if (out?.params?.IdentityId) {
                    dispatch({ type: LOGIN.SUCCESS, payload: { profileDetails: result, awsCredentials: out.params } })
                } else {
                    dispatch({ type: LOGIN.FAILURE, payload: { error: 'Something went wrong!!!' } })
                }
            })
        ).catch(err => {
            konsole.error(err);
            dispatch({ type: LOGIN.FAILURE, payload: { error: 'Something went wrong!!!' } });
        })

export const reinitialize = (accessToken, idToken) => (dispatch) => {
    dispatch({ type: REINITIALIZE.REQUEST })
    setAWSCognitoCredentials(idToken, true)
        .then(awscred => {
            if (awscred?.params?.IdentityId) {
                AuthService.getUserInfo(accessToken)
                    .then(user => {
                        dispatch(getUserDetails(awscred.params.IdentityId));
                        dispatch({
                            type: REINITIALIZE.SUCCESS, payload: {
                                awsCredentials: awscred.params,
                                profileDetails: user,
                            }
                        });
                    })
                    .catch(error => dispatch({ type: REINITIALIZE.FAILURE, payload: { error } }))
            } else {
                dispatch({ type: REINITIALIZE.FAILURE, payload: { error: 'Session Expired!' } })
            }
        })
        .catch(res => { konsole.log('loginAction:-', res) })
}