import React from 'react';
import { Tooltip, IconButton, SvgIcon } from '@material-ui/core';
import FaceIcon from '@material-ui/icons/Face';
export const ProfileIcon = ({ size, handleClickOpen, ...rest }) => {
    return (
        <>
            <Tooltip title='Profile Photo' arrow>
                <IconButton onClick={handleClickOpen} {...rest}
                >
                    <SvgIcon fontSize={size}>
                        <FaceIcon />
                    </SvgIcon>
                </IconButton>
            </Tooltip>
        </>
    );
}