import { makeStyles } from '@material-ui/core';
export const styleForgetPassword = makeStyles((theme) => ({
    fpwdRoot: {
        backgroundColor: theme.palette.background.dark,
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',

    },
    fpwdHeading: {
        padding: theme.spacing(3),
    },
    fpwdSubmitBtn: {
        paddingBottom: theme.spacing(3),
        textAlign: 'center'
    },
    fpwdContinueBtn: {
        justifyContent: 'center',
        textAlign: 'center'
    },
    fpwdInput: {
        "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px " + theme.palette.background.default + " inset",
        }
    },
}));