import React from 'react';
import { Lightbox } from 'react-modal-image';
export const PictureModal = ({ url, handleClose }) => {
    return (
        <>
            <Lightbox
                medium={url}
                large={url}
                onClose={handleClose}
                hideDownload={false}
                showRotate={true}
                hideZoom={false}
            />
        </>
    );
}