import { makeStyles } from '@material-ui/core';
export const styleResetPassword = makeStyles((theme) => ({
    secTxtField: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: '0'
        },
        [theme.breakpoints.up('sm')]: {
            marginLeft: '25%'
        }

    },
    fpwdHeading: {
        padding: theme.spacing(4),
    },
    fpwdRoot: {
        backgroundColor: theme.palette.background.dark,
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%',

    },
}));
