import { apiPostSignup, apiPatchEmailVerification } from './../../services/apis/account/signup';
import { apiGetIsEmailUnique } from './../../services/apis/account/common';
import { setAWSCognitoCredentials } from './../../services/AWSCognitoService';
import { AuthService } from './../../services/AuthService';
import { konsole, Loader, resolveError } from './../../utils';

export const SIGNUP = {
    REQUEST: '@account/signup-request',
    SUCCESS: '@account/signup-success',
    FAILURE: '@account/signup-failure',
}
export const SIGNUP_SOCIAL = {
    REQUEST: '@account/signup-social-request',
    SUCCESS: '@account/signup-social-success',
    FAILURE: '@account/signup-social-failure',
}
export const EMAIL_VERIFICATION = {
    REQUEST: '@account/email-verfication-request',
    SUCCESS: '@account/email-verfication-success',
    FAILURE: '@account/email-verfication-failure',
}
export const userSignup = (email, password) => (dispatch) => {
    dispatch({ type: SIGNUP.REQUEST });
    apiGetIsEmailUnique(email)
        .then(user => {
            const { success, data, error } = user;
            if (success && data?.isUnique) {
                Loader.show();
                AuthService.signupWithCredentials(email, password)
                    .then(newuser => setAWSCognitoCredentials(newuser.idToken)
                        .then(awscred => {
                            AuthService.getUserInfo(newuser.accessToken)
                                .then(res => {
                                    if (res?.sub) {
                                        apiPostSignup(email, res?.sub)
                                            .then((x) => {
                                                if (x.success) {
                                                    dispatch({ type: SIGNUP.SUCCESS })
                                                }
                                                else {
                                                    dispatch({ type: SIGNUP.FAILURE, payload: { error: x.data } });
                                                }
                                            })
                                    } else {
                                        Loader.hide();
                                        dispatch({ type: SIGNUP.FAILURE, payload: { error: 'Unable to register!!' } });
                                    }
                                })
                        }
                        ))
                    .catch(err => {
                        Loader.hide();
                        dispatch({ type: SIGNUP.FAILURE, payload: { error: resolveError(err) } });
                    })
            } else {
                konsole.log(error);
                dispatch({ type: SIGNUP.FAILURE, payload: { error: 'Already registered!!' } });
            }
        })
}

export const emailVerification = (id) => (dispatch) => {
    dispatch({ type: EMAIL_VERIFICATION.REQUEST })
    apiPatchEmailVerification(id)
        .then(out => {
            const { success, error } = out;
            if (success) {
                dispatch({ type: EMAIL_VERIFICATION.SUCCESS })
            } else {
                if (error.response.data === 'UserNotFound') {
                    dispatch({ type: EMAIL_VERIFICATION.FAILURE, payload: { error: error.response.data } })
                } else {
                    dispatch({ type: EMAIL_VERIFICATION.FAILURE, payload: { error: resolveError(error) } });
                }
            }
        })
}

export const userSocialSignup = (email, socialType, socialId) => (dispatch) => {
    apiGetIsEmailUnique(email)
        .then((result) => {
            const { success, data, error } = result;
            if (success) {
                if (data.isUnique) {
                    apiPostSignup(email, socialId, socialId, socialType)
                        .then((x) => {
                            if (x.success) {
                                dispatch({ type: SIGNUP_SOCIAL.SUCCESS });
                            } else {
                                konsole.log(x);
                                dispatch({ type: SIGNUP_SOCIAL.FAILURE, payload: { error: resolveError(x.error) } });
                            }
                        })
                } else if (!data.isUnique && data.emailVerified && data.registrationSource === 'native') {
                    dispatch({ type: SIGNUP_SOCIAL.FAILURE, payload: { error: 'Already registered! Please use different credentials!' } })
                }
                else if (!data.isUnique && data.emailVerified && data.registrationSource !== 'native') {
                    // case for same emailid
                    dispatch({ type: SIGNUP_SOCIAL.SUCCESS });
                }
                else if (!data.isUnique) {
                    dispatch({ type: SIGNUP_SOCIAL.FAILURE, payload: { error: 'Already registered! Please use different credentials!' } })
                }
            } else {
                konsole.log(error)
                dispatch({ type: SIGNUP_SOCIAL.FAILURE, payload: { error: 'Something went wrong!!' } });
            }
        })
}

export const integrateWithSocial = (socialType, conName) => () =>
    AuthService.integrationWithSocial(socialType, conName);