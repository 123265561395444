import React from 'react';

export const Logo = ({ imgpath, styles }) => {
  return (
    <img
      alt="Logo"
      src={imgpath}
      className={styles}
    />
  );
}
