import { apiGetIsUsernameUnique, apiPatchUserDetails, apiGetUserDetails } from './../../../services/apis/account/userinfo';
import { AuthService } from './../../../services/AuthService';
import { AuthMgmtService } from './../../../services/AuthMgmtService';
import { AuthTokenService } from './../../../services/AuthTokenService';
import { resolveError } from '../../../utils';

export const USER_UPDATE_DETAILS = {
    SUCCESS: '@account/update-user-details-success',
    FAILURE: '@account/update-user-details-failure',
    REQUEST: '@account/update-user-details-request',
}
export const USER_FETCH_DETAILS = {
    REQUEST: '@account/fetch-user-details-request',
    SUCCESS: '@account/fetch-user-details-success',
    FAILURE: '@account/fetch-user-details-failure',
}

export const CHANGE_PASSWORD = {
    REQUEST: '@account/change-password-request',
    SUCCESS: '@account/change-password-success',
    FAILURE: '@account/change-password-failure',
}

export const CHECK_UNIQUE_USER = {
    REQUEST: '@account/check-unique-username-request',
    SUCCESS: '@account/check-unique-username-success',
    FAILURE: '@account/check-unique-username-failure',
}

export const UPDATE_URL = {
    SUCCESS: '@account/update-url-success',
}

export const checkUniqueUsername = (username) => (dispatch) => {
    dispatch({ type: CHECK_UNIQUE_USER.REQUEST })
    apiGetIsUsernameUnique(username)
        .then((out) => {
            const { success, data, error } = out;
            if (success) {
                if (!data.isUnique) {
                    dispatch({ type: CHECK_UNIQUE_USER.FAILURE, payload: { error: 'Username should be unique!!' } })
                } else {
                    dispatch({ type: CHECK_UNIQUE_USER.SUCCESS })
                }
            } else {
                dispatch({ type: CHECK_UNIQUE_USER.FAILURE, payload: { error: resolveError(error) } })
            }
        })
}

export const updateUserDetails = (userDetails = {}) => (dispatch) => {
    dispatch({ type: USER_UPDATE_DETAILS.REQUEST })
    apiPatchUserDetails(userDetails)
        .then(res =>
            dispatch({ type: USER_UPDATE_DETAILS.SUCCESS, payload: { user: userDetails } })
        )
        .catch(error =>
            dispatch({ type: USER_UPDATE_DETAILS.FAILURE, payload: { error } })
        )
}

export const changePassword = (email, pwdOld, pwdNew) => (dispatch) => {
    dispatch({ type: CHANGE_PASSWORD.REQUEST })
    AuthService.checkUserCrendentials(email, pwdOld)
        .then((out1) => AuthService.getUserInfo(out1.accessToken)
            .then((out) => {
                AuthTokenService.getToken()
                    .then(res => {
                        const authMgmtObj = new AuthMgmtService(res.accessToken);
                        authMgmtObj.changePassword(out.sub, pwdNew);
                        dispatch({ type: CHANGE_PASSWORD.SUCCESS })
                    })
                    .catch(error => {
                        dispatch({ type: CHANGE_PASSWORD.FAILURE, payload: { error: resolveError(error) } });
                    })
            })

        )
        .catch(error => {
            dispatch({ type: CHANGE_PASSWORD.FAILURE, payload: { error: 'Your Old password is incorrect!' } });
        })
}

export const getUserDetails = () => (dispatch) => {
    dispatch({ type: USER_FETCH_DETAILS.REQUEST })
    apiGetUserDetails()
        .then((user) => {
            const { success, data, error } = user;
            if (success) {
                dispatch({ type: USER_FETCH_DETAILS.SUCCESS, payload: { profileDetails: data } })
            }
            else {
                dispatch({ type: USER_FETCH_DETAILS.FAILURE, payload: { error } })
            }
        })
}