import { THEMES } from './constants';

function getKey(key, dontUseReactAppPrefix = false) {
    return process.env[dontUseReactAppPrefix ? key : `REACT_APP_${key}`] || '';
}

export const CONFIG = {
    ui: {
        direction: 'ltr',
        responsiveFontSizes: true,
        theme: THEMES.UNICORN
    },
    isDevEnv: getKey('NODE_ENV', true).toLowerCase() === 'development',
    apiBaseUrl: getKey('API_BASE_URL'),
    apiName: getKey('API_NAME'),
    organizationId: getKey('ORGANIZATION_ID'),
    pageLimit: getKey('PAGE_LIMIT'),
    auth0: {
        domain: getKey('AUTH0_DOMAIN_NAME'),
        clientID: getKey('AUTH0_CLIENT_ID'),
        responseType: getKey('AUTH0_RESPONSE_TYPE'),
        redirectUri: getKey('AUTH0_REDIRECT_URL'),
        responseMode: getKey('AUTH0_RESPONSE_MODE'),
        scope: getKey('AUTH0_SCOPE'),
    },
    auth0Mgmt: {
        clientID: getKey('AUTH0_MGMT_CLIENTID'),
        clientIDValue: getKey('AUTH0_MGMT_CLIENTID_VALUE'),
        clientSecret: getKey('AUTH0_MGMT_CLIENT_SECRET'),
        clientSecretValue: getKey('AUTH0_MGMT_CLIENT_SECRET_VALUE'),
        grantType: getKey('AUTH0_MGMT_GRANT_TYPE'),
        grantTypeValue: getKey('AUTH0_MGMT_GRANT_TYPE_VALUE'),
        audience: getKey('AUTH0_MGMT_AUDIENCE'),
        audienceValue: getKey('AUTH0_MGMT_AUDIENCE_VALUE'),
        url: getKey('AUTH0_MGMT_URL'),
    },
    connection: {
        twitter: getKey('TWITTER_CONNECTION_NAME'),
        google: getKey('GOOGLE_CONNECTION_NAME'),
        native: getKey('NATIVE_CONNECTION_NAME'),
    },
    aws: {
        region: getKey('AWS_COGNITO_REGION'),
        identityPoolId: getKey('AWS_COGNITO_IDENTITY_POOLID'),
        roleUnauth: getKey('AWS_COGNITO_UNAUTH_ROLE'),
        roleAuth: getKey('AWS_COGNITO_AUTH_ROLE'),
        login: getKey('AWS_COGNITO_LOGIN'),
        videoBucket: getKey('AWS_S3_VIDEO_BUCKET')
    }
}
