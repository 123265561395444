import { FETCH_PERFORMERS, UPDATE_PERFORMERS, CREATE_PERFORMER } from './../../../../actions/profile/admin/management/performerAction';
const iniState = {
    performers: [],
    errorPerformer: null,
    count: 0,
    performerUpdated: null,
    errorPerformerUpdated: null,
    performerCreated: null,
    errorPerformerCreated: null
}

export const PerformerReducer = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case FETCH_PERFORMERS.REQUEST:
            return { ...iniState }
        case FETCH_PERFORMERS.SUCCESS:
            const { performers, count } = payload;
            state.performers = performers;
            state.errorPerformer = null;
            state.errorPerformerUpdated = null;
            state.errorPerformerCreated = null;
            if (performers.length === 0) {
                state.count = 0;
            }
            if (count) {
                state.count = count;
            }
            return { ...state };
        case FETCH_PERFORMERS.FAILURE:
            state.performers = [];
            state.errorPerformer = payload.error;
            return { ...state }
        case UPDATE_PERFORMERS.SUCCESS:
            state.performerUpdated = payload.performerId;
            state.errorPerformerUpdated = null;
            return { ...state };
        case UPDATE_PERFORMERS.FAILURE:
            state.performerUpdated = null;
            state.errorPerformerUpdated = payload.error;
            return { ...state };
        case CREATE_PERFORMER.SUCCESS:
            state.performerCreated = payload.performerId;
            state.errorPerformerCreated = null;
            return { ...state };
        case CREATE_PERFORMER.FAILURE:
            state.performerCreated = null;
            state.errorPerformerCreated = payload.error;
            return { ...state };
        default:
            return { ...iniState }
    }
}