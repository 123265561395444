import React from 'react';
import { TextField } from '@material-ui/core';
export const TextBox = ({ text, name, setName, showError }) => {
    return (
        <TextField
            error={Boolean(name === '' && showError)}
            helperText={name === '' && showError}
            variant='outlined'
            fullWidth
            size='small'
            name={text}
            label={text.charAt(0).toUpperCase() + text.slice(1)}
            value={name}
            onChange={(event) => setName(event.target.value)}
            style={{ paddingBottom: '15px' }} />
    );
}