import auth0 from 'auth0-js';
import { CONFIG } from './../config';
import { konsole, BrowserStorage, StorageKey } from './../utils';

const { scope, ...auth0Config } = CONFIG.auth0;

export class AuthService {
    static auth0 = new auth0.WebAuth(auth0Config);

    static integrationWithSocial = (socialType, conName) => {
        BrowserStorage.set(StorageKey.isSocialIntegration, true);
        BrowserStorage.set(StorageKey.socialType, socialType);
        this.auth0.authorize({
            connection: conName
        });
    }

    static integrationWithTwitter = () => {
        this.auth0.authorize({
            connection: CONFIG.connection.twitter
        });
    }

    static loginWithCredentials = (email, password) => new Promise((resolve, reject) => {
        this.auth0.login({
            realm: CONFIG.connection.native,
            connection: CONFIG.connection.native,
            username: email,
            password: password,
            scope,
        }, (err) => {
            if (err) {
                reject(err);
            }
        });
    });

    static signupWithCredentials = (email, password) => new Promise((resolve, reject) => {
        this.auth0.signupAndAuthorize({
            connection: CONFIG.connection.native,
            email: email,
            password: password,
            realm: CONFIG.connection.native,
        }, (err, authResult) => {
            if (err) {
                konsole.log(err)
                reject(err);
            } else if (authResult) {
                resolve(authResult);
            }
        });
    });

    static getUrlInfo = () => new Promise((resolve, reject) => {
        BrowserStorage.removeAll('at', 'it')
        this.auth0.parseHash({ hash: window.location.hash },
            (error, authResult) => {
                if (error) {
                    reject(error);
                }
                else {
                    BrowserStorage.set(StorageKey.accessToken, authResult.accessToken);
                    BrowserStorage.set(StorageKey.idToken, authResult.idToken);
                    return resolve(authResult);
                }
            });
    });

    static logoutUser = () => {
        this.auth0.logout();
        BrowserStorage.clear();
    }
    static checkSession = () => {
        this.auth0.checkSession(
            {
                audience: CONFIG.auth0Mgmt.audienceValue,
                scope,
                responseType: CONFIG.auth0.responseType
            }, function (err, result) {
                // use result.accessToken
                konsole.log(err, result)
            }
        );
    }

    static getUserInfo = (token) => new Promise((resolve, reject) => {
        konsole.log(token)
        this.auth0.client.userInfo(token,
            (err, user) => {
                // use result.accessToken
                konsole.log(err, user);
                if (err) {
                    if (err.statusCode === 401) {
                        BrowserStorage.clear();
                        return reject('Session Expired')
                    } else {
                        return reject(err);
                    }

                }
                resolve(user);
            }
        );
    })

    static checkUserCrendentials = (email, password) => new Promise((resolve, reject) => {
        this.auth0.client.login(
            {
                realm: CONFIG.connection.native,
                username: email,
                password: password,
                connection: CONFIG.connection.native
            },
            (err, authResult) => {
                konsole.log(err, authResult)
                if (err) {
                    return reject(err);
                }
                return resolve(authResult);
            }
        );
    })

}