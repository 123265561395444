import React from 'react';
import { Container } from '@material-ui/core';
import { Page, Separator } from '../..';
import { styleTermsCondition } from './TermsCondition.style';
export const TermsCondition = () => {
    const classes = styleTermsCondition();
    return (
        <Page
            className={classes.apaRoot}
            title="Term and Service"
        >
            <Container maxWidth="lg">
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Welcome to CCI"
                    className={classes.apaContent}
                />
                <Separator
                    color="textSecondary"
                    variant="subtitle1"
                    //align="center"
                    text="This is our terms and conditions page" />
            </Container>
        </Page>
    );
}
