import React, { useState, useContext } from 'react';
import Uploady, { useItemProgressListener, useItemFinishListener, UploadyContext, useItemErrorListener } from "@rpldy/uploady";
import DoneIcon from '@material-ui/icons/Done';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { UPDATE_URL } from './../../../actions/profile/user/userAction';
import { konsole, Loader } from './../../../utils';
import { apiGetSignedUploadUrl } from '../../../services/apis/account/userinfo';

const UploadProgress = ({ onFile, isError, onError }) => {
    const [uploads, setUploads] = useState({});
    const progressData = useItemProgressListener();
    const [state, setState] = useState('error');
    useItemFinishListener((item) => {
        setState(item.state);
        onFile && onFile(item.id);
    });

    useItemErrorListener((item) => {
        Loader.hide();
        onError('Error while uploading....');
        setState(item.state);
    })

    if (progressData && state !== 'finished') {
        Loader.show();
    }
    if (progressData && progressData.completed) {
        const upload = uploads[progressData.id] ||
            { name: progressData.url || progressData.file.name, progress: [0] };

        if (!~upload.progress.indexOf(progressData.completed)) {
            upload.progress.push(progressData.completed);

            setUploads({
                [progressData.id]: upload,
            });
            setState('error')
        }
    }
    const entries = Object.entries(uploads);
    return <div>
        {entries.map(([id, { progress, name }]) => {
            const lastProgress = progress[progress.length - 1];
            return <div style={{ display: 'flex' }} key={name}>
                <p style={{
                    marginLeft: '5px', marginTop: '2px', fontWeight: 200, lineHeight: 1.05,
                }}>
                    {isError ? '' :
                        <>
                            {name}
                            {lastProgress === 100 && state !== 'error' &&
                                <DoneIcon style={{ marginLeft: '5px' }} />
                            }
                        </>
                    }
                </p>
            </div>
        })}
    </div>;
};

const UploadButton = ({ idType, caption, isError, fileFilter, errMsg, successCaption, isEnabled }) => {
    const dispatch = useDispatch();
    const { imageUrl, imageIdUrl, user: { profileDetails } } = useSelector(state => state.user);
    const uploady = useContext(UploadyContext);
    let url = null;
    let getUrl = null;
    if (idType === 'id') {
        url = imageUrl?.uploadUrl;
        getUrl = profileDetails?.image;
    } else if (idType === 'holdingid') {
        url = imageIdUrl?.uploadUrl;
        getUrl = profileDetails?.holdingIdImage;
    }

    if (isEnabled) {
        getUrl = '';
    }
    const onClick = async () => {
        try {
            if (!url) {
                await apiGetSignedUploadUrl(idType)
                    .then((out) => {
                        const { success, data } = out;
                        konsole.log(out);
                        if (success) {
                            url = data.uploadUrl;
                            dispatch({ type: UPDATE_URL.SUCCESS, payload: { urls: data, type: idType } })
                        }
                    })
            }
            uploady.showFileUpload({
                destination: {
                    url,
                    method: 'PUT',
                    headers: {
                        "Content-Type": '',
                    },
                },
                sendWithFormData: false,
                fileFilter: (e) => fileFilter(e)
            });

        } catch (error) {
            Loader.hide();
            konsole.error(error, error.response);
            throw error;
        }
    }

    return <>
        <Button type='button'
            disabled={getUrl ? true : false}
            onClick={onClick}
            style={{
                border: isError ? '1px solid red' : '1px solid rgba(166, 125, 255, 0.5)',
                color: isError ? 'red' : '#a67dff'
            }}
        >
            {getUrl ? successCaption : caption}</Button>
        <div>
            <span style={{ color: isError ? 'red' : '#a67dff' }}>{isError ? errMsg : ''}</span>
        </div>
    </>
}

export const ImageUploadButton = ({ caption, idType, successCaption, selectedFile, fileFilter, isError, errMsg, onError, isEnabled = false }) => {
    return (
        <Uploady>
            <UploadButton idType={idType}
                caption={caption}
                isError={isError}
                errMsg={errMsg}
                successCaption={successCaption}
                fileFilter={e => fileFilter(e)}
                isEnabled={isEnabled} />
            <UploadProgress
                onFile={e => {
                    selectedFile(e);
                    Loader.hide();
                }}
                isError={isError}
                onError={onError}
            />
        </Uploady>
    );
}