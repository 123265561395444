import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText } from '@material-ui/core';

export const ContentDeletionDialogBox = ({ handleClose, title, contentName }) => {
    return (
        <Dialog open={true} onClose={handleClose}>
            <DialogTitle style={{ alignSelf: 'center' }}>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText style={{ textAlign: 'center' }}>
                    {'Are you sure you want to delete '}
                    {'"' + contentName + '"?'}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">Disagree</Button>
                <Button onClick={e => handleClose('confirm')} color="primary" autoFocus>Agree</Button>
            </DialogActions>
        </Dialog>
    )
}