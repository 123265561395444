import { CONTENT_MANAGER_FETCH, CONTENT_MANAGER_STATUS_UPDATE } from './../../../../actions/profile/admin/management/contentAction';

const iniState = {
    errorContent: null,
    contents: [],
    count: 0,
    contentStatus: null,
    errorContentStatus: null
}

export const ContentsReducers = (state = iniState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CONTENT_MANAGER_FETCH.REQUEST:
            return { ...iniState };
        case CONTENT_MANAGER_FETCH.SUCCESS:
            const { content, count } = payload;
            state.contents = content;
            state.errorContent = null;
            if (content.length === 0) {
                state.count = 0;
            }
            if (count) {
                state.count = count;
            }
            return { ...state }
        case CONTENT_MANAGER_FETCH.FAILURE:
            state.errorContent = payload.error;
            return { ...state };
        case CONTENT_MANAGER_STATUS_UPDATE.REQUEST:
            state.contentStatus = null;
            state.errorContentStatus = null;
            return { ...state }
        case CONTENT_MANAGER_STATUS_UPDATE.SUCCESS:
            state.contentStatus = payload.videoId;
            state.errorContentStatus = null;
            return { ...state }
        case CONTENT_MANAGER_STATUS_UPDATE.FAILURE:
            state.contentStatus = null;
            state.errorContentStatus = payload.error;
            return { ...state }
        default:
            return { ...iniState };
    }
}
