import { API } from 'aws-amplify';
import { BrowserStorage } from "./browserStorage";
import { StorageKey } from "./storageKeys";
import { CONFIG } from './../config';
import { Loader } from './loader';

const setOptions = ({ dontUseAuth, headers, ...options }) => {
    const updatedHeaders = dontUseAuth ? headers : ({
        ...headers,
        Authorization: BrowserStorage.get(StorageKey.cognitoId),
    });
    return { ...options, headers: updatedHeaders };
}

function formatPayload(prom, isLoading = true) {
    if (isLoading)
        Loader.show();
    return Promise.resolve(
        prom.then(data => {
            if (isLoading)
                Loader.hide();
            return { success: true, data }
        })
            .catch(error => {
                Loader.hide();
                return { success: false, error }
            })
    );

}

export const Http = {
    get: (url, options = {}, isLoading = true) => formatPayload(API.get(CONFIG.apiName, url, setOptions(options)), isLoading),
    post: (url, options = {}) => formatPayload(API.post(CONFIG.apiName, url, setOptions(options))),
    patch: (url, options = {}) => formatPayload(API.patch(CONFIG.apiName, url, setOptions(options))),
    delete: (url, options = {}) => formatPayload(API.delete(CONFIG.apiName, url, setOptions(options))),
}
