import React from 'react';
import { Container, Box, Button } from '@material-ui/core';
import { Page, Separator } from '../..';
import { Link as RouterLink } from 'react-router-dom';
import { styleAccountVerification } from './AccountEmailVerification.style';
export const AccountEmailVerification = () => {
    const classes = styleAccountVerification();
    return (
        <Page
            className={classes.accCnfRoot}
            title="Account Email Verification"
        >
            <Container maxWidth="lg">
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="h1"
                    text="Account Email Verification"
                    className={classes.accCnfHeading}
                />
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="We have sent an email with a confirmation link to your email address." />
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="To verify your email address, click the confirmation link in the email." />
                <Separator
                    align="center"
                    color="textSecondary"
                    variant="subtitle1"
                    text="If you do not receive a confirmation email, check your spam folder." />
                <Box mt={6} className={classes.accCnfContinueBtn}
                >
                    <Button
                        color="secondary"
                        size="small"
                        variant="outlined"
                        component={RouterLink}
                        to='/'
                    >
                        Continue to site
                    </Button>
                </Box>
            </Container>
        </Page>
    );
}
