import React from 'react';
import { Separator, Logo } from '../';
import { Button } from '@material-ui/core';
import TwitterIcon from '@material-ui/icons/Twitter';
import { styleSocialAuthButtons } from './SocialAuthButtons.style';
import { integrateWithSocial } from '../../actions/account/signupAction';
import { useDispatch } from 'react-redux';
import { SocialIntegrationTypes } from '../../constants';
import { CONFIG } from './../../config';
export const SocialAuthButtons = ({ text }) => {
    const classes = styleSocialAuthButtons();
    const dispatch = useDispatch();
    return (
        <>
            <Separator className={classes.separator} text="OR" color="textSecondary" variant="overline" />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnTwitter}
                startIcon={<TwitterIcon />}
                onClick={() => dispatch(integrateWithSocial(SocialIntegrationTypes.TWITTER, CONFIG.connection.twitter))}
            >
                {text + ' with Twitter'}
            </Button>
            <Separator className={classes.extraSeparator} text="OR" color="textSecondary" variant="overline" margin='0' />
            <Button
                variant="contained"
                color="primary"
                size="small"
                className={classes.btnGoogle}
                startIcon={<Logo imgpath='/static/google.png' styles={classes.googleIcon} />}
                onClick={() => dispatch(integrateWithSocial(SocialIntegrationTypes.GOOGLE, CONFIG.connection.google))}
            >
                {text + ' with Google'}
            </Button>
        </>
    );
}