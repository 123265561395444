import { Http } from '../../../../../utils';

export const apiGetCreatorsProfile = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key,
            sortBy: settings.sortBy
        }
    }
    return Http.get('/clientapi/admin/creators', options)
}

export const apiPatchUpdateCreatorProfileStatus = (username, status) => {
    const options = {
        body: {
            status
        }
    }
    return Http.patch('/clientapi/admin/status/' + username, options)
}

export const apiGetCreatorSignedUrl = (username, id) => {
    const options = {
        queryStringParameters: {
            username,
            type: id
        }
    }
    return Http.get('/clientapi/image', options);
}

export const apiGetFilteredCreators = (settings) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit,
            status: settings.status,
            key: settings.key
        }

    }
    return Http.get('/clientapi/admin/creators/' + settings.query, options);
}

export const apiPatchUpdateCreatorProfile = (userDetails, username, type = '') => {
    const options = {
        queryStringParameters: {
            type
        },
        body: {
            ...userDetails
        }

    }
    return Http.patch('/clientapi/admin/creator/' + username, options);
}

export const apiGetCreatorProfile = (username) => {
    const options = {
        queryStringParameters: {
            username
        }
    }
    return Http.get('/clientapi/admin/creator', options);
}