import { makeStyles } from '@material-ui/core';
export const styleLoadingScreen = makeStyles((theme) => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.background.default,
        opacity: '0.7',
        //display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        display: 'none'
    }
}));