import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Redirect } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { reinitialize } from './../../actions/account/loginAction';
import { konsole, BrowserStorage, StorageKey, Loader } from './../../utils';
export const AuthGuard = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { reinitializeError } = useSelector(state => state.login);
    const { user: { profileDetails } } = useSelector(state => state.user);
    const accessToken = BrowserStorage.get(StorageKey.accessToken);
    const idToken = BrowserStorage.get(StorageKey.idToken);
    useEffect(() => {
        if (Object.keys(profileDetails).length === 0 && accessToken && idToken) {
            konsole.log('reinit');
            Loader.show();
            dispatch(reinitialize(accessToken, idToken))
        }
        else if (!Object.keys(profileDetails).length === 0 || !accessToken || !idToken || reinitializeError) {
            history.push('/')
        }
    }, [dispatch, accessToken, idToken, history, profileDetails, reinitializeError])

    if (reinitializeError) {
        BrowserStorage.clear();
        Loader.hide();
        return <Redirect to='/' />
    }
    return children;
}

AuthGuard.prototype = {
    children: PropTypes.any
};