
import { apiGetAllCategories, apiPostCreateCategory, apiPatchUpdateCategory } from './../../../../services/apis/videoinfo/category';
export const FETCH_CATEGORY = {
    REQUEST: '@account/admin-fetch-category-request',
    SUCCESS: '@account/admin-fetch-category-success',
    FAILURE: '@account/admin-fetch-category-failure',
}

export const UPDATE_CATEGORY = {
    REQUEST: '@account/admin-update-category-request',
    SUCCESS: '@account/admin-update-category-success',
    FAILURE: '@account/admin-update-category-failure',
}

export const CREATE_CATEGORY = {
    REQUEST: '@account/admin-create-category-request',
    SUCCESS: '@account/admin-create-category-success',
    FAILURE: '@account/admin-create-category-failure',
}

export const fetchCategories = () => (dispatch) =>
    apiGetAllCategories()
        .then(result => {
            const { success, data, error } = result;
            if (success && Object.keys(data.categories).length > 0) {
                dispatch({ type: FETCH_CATEGORY.SUCCESS, payload: { categories: Object.values(data.categories) } })
            } else {
                dispatch({ type: FETCH_CATEGORY.FAILURE, payload: { error } })
            }
        })

export const updateCategory = settings => (dispatch) =>
    apiPatchUpdateCategory(settings)
        .then(result => {
            const { success, error } = result;
            if (success) {
                dispatch({ type: UPDATE_CATEGORY.SUCCESS, payload: { category: settings } })
            } else {
                dispatch({ type: UPDATE_CATEGORY.FAILURE, payload: { error } })
            }
        })

export const createCategory = settings => dispatch =>
    apiPostCreateCategory(settings)
        .then(result => {
            const { success, error } = result;
            if (success) {
                dispatch({ type: CREATE_CATEGORY.SUCCESS, payload: { categoryName: settings.name } })
            } else {
                dispatch({ type: CREATE_CATEGORY.FAILURE, payload: { error } })
            }
        })