import AWS from "aws-sdk";
import { CONFIG } from './../config';
import Amplify from 'aws-amplify';
import { konsole, StorageKey, BrowserStorage } from './../utils';

export const setAWSCognitoCredentials = (token, isloggedin = false) => new Promise((resolve, reject) => {
    try {
        AWS.config.region = CONFIG.aws.region;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            RoleArn: isloggedin ? CONFIG.aws.roleAuth : CONFIG.aws.roleUnauth,
            IdentityPoolId: CONFIG.aws.identityPoolId,
            Logins: {
                [CONFIG.aws.login]: token
            },
        });
        AWS.config.credentials.clearCachedId();
        AWS.config.credentials.refresh();
        initializeAmplify();
        konsole.log(AWS.config.credentials);
        AWS.config.credentials.get(() => {
            BrowserStorage.set(StorageKey.cognitoId, AWS.config.credentials.params.IdentityId);
            return resolve(AWS.config.credentials);
        });
    }
    catch (error) {
        reject(error);
    }
});

export const getAWSCognitoCredentials = () => new Promise((resolve, reject) => {
    AWS.config.credentials.get(() => {
        BrowserStorage.set(StorageKey.cognitoId, AWS.config.credentials.params.IdentityId);
        return resolve(AWS.config.credentials);
    });
});

export const initializeAmplify = () => {
    Amplify.configure({
        Auth: AWS.config.credentials,
        API: {
            endpoints: [{
                name: CONFIG.apiName,
                endpoint: CONFIG.apiBaseUrl,
                region: CONFIG.aws.region
            }]
        }
    });
    Amplify.API.configure(AWS.config.credentials);
}

export const setAmplifyConfig = () => {
    Amplify.configure({
        Auth: {
            region: CONFIG.aws.region
        },
        API: {
            endpoints: [{
                name: CONFIG.apiName,
                endpoint: CONFIG.apiBaseUrl,
                region: CONFIG.aws.region
            }]
        }
    });
}