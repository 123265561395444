import { Http } from '../../../utils';
export const apiGetPerformers = ({ limit, key, status, sortBy = '' }) => {
    const options = {
        queryStringParameters: {
            limit,
            key,
            status,
            sortBy
        }
    }
    return Http.get('/clientapi/performers', options)
}

export const apiGetFilterPerformers = (settings, isLoading) => {
    const options = {
        queryStringParameters: {
            limit: settings.limit ?? 50,
            status: settings.status ?? '',
            key: settings.key ?? '',
            sortBy: settings.sortBy ?? ''
        }
    }
    return Http.get('/clientapi/performers/' + settings.name, options, isLoading);
}


export const apiPostCreatePerformer = (data) => {
    const options = {
        body: {
            ...data
        }
    }
    return Http.post('/clientapi/performer', options);
}

export const apiPatchUpdatePerformer = (data) => {
    const options = {
        body: {
            ...data
        }
    }
    return Http.patch('/clientapi/performer/' + data.performerId, options);
}

