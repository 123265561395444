import React from 'react';
import './App.css';
import { createTheme } from './theme/index';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core';
import { AppRoute } from './routes/Routes';
import { Router } from 'react-router-dom';
import { CONFIG } from './config';
import { version } from '../package.json';
import { SnackbarProvider } from 'notistack';
import { AuthTokenService } from './services/AuthTokenService';
import { setAmplifyConfig } from './services/AWSCognitoService';
import { BrowserStorage, StorageKey } from './utils';
import { LoadingScreen } from './components';
const history = createBrowserHistory();

const appendVersion = () => {
  document.documentElement.setAttribute('app-version', version);
}

const initializeService = () => {
  AuthTokenService.getToken()
    .then((out) => {
      BrowserStorage.set(StorageKey.mgToken, out.accessToken);
      setAmplifyConfig();
    })
}

const App = () => {
  appendVersion();
  initializeService();

  return (
    <ThemeProvider theme={createTheme(CONFIG.ui)}>
      <SnackbarProvider maxSnack={5}>

        <Router history={history}>
          <AppRoute />
          <LoadingScreen />
        </Router>
      </SnackbarProvider>
    </ThemeProvider >
  );
}

export default App;
