import React, { useEffect, useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { userSocialSignup } from '../../actions/account/signupAction';
import { apiGetUserDetails } from '../../services/apis/account/userinfo';
import { BrowserStorage, StorageKey, Loader } from '../../utils';
import { USER_FETCH_DETAILS } from '../../actions/profile/user/userAction';
import { Redirect } from 'react-router-dom';

export const SocialAuthorize = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const { isSocialUserLoggedIn, errorSignup } = useSelector(state => state.signup)
    const { user: { profileDetails } } = useSelector(state => state.login);
    const userid = profileDetails?.sub ?? profileDetails?.idTokenPayload?.sub;
    const email = profileDetails?.email ?? profileDetails?.idTokenPayload?.email;
    const socialType = BrowserStorage.get(StorageKey.socialType);
    const getUserInfo = useCallback(() => {
        apiGetUserDetails()
            .then((user) => {
                const { success, data, error } = user;
                if (success) {
                    history.push('/dashboard')
                    dispatch({ type: USER_FETCH_DETAILS.SUCCESS, payload: { profileDetails: data } })
                }
                else {
                    history.push('/')
                    enqueueSnackbar('Something went wrong!', {
                        variant: 'error'
                    });
                    dispatch({ type: USER_FETCH_DETAILS.FAILURE, payload: { error } })
                }
            })
    }, [dispatch, enqueueSnackbar, history]);

    const checkSignUpUser = useCallback(() => {
        if (isSocialUserLoggedIn) {
            getUserInfo();
        } else {
            dispatch(userSocialSignup(email, socialType, userid))
        }
    }, [isSocialUserLoggedIn, dispatch, getUserInfo, email, socialType, userid])

    useEffect(() => {
        checkSignUpUser();
    }, [checkSignUpUser])

    if (errorSignup) {
        BrowserStorage.clear();
        Loader.hide();
        enqueueSnackbar(errorSignup, {
            variant: 'error'
        });
        return <Redirect to='/' />
    }
    return null;
}