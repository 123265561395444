import { Http } from '../../../utils';
export const apiGetAllCategories = () => Http.get('/clientapi/categories')

export const apiPostCreateCategory = (data) => {
    const options = {
        body: {
            ...data
        }
    }
    return Http.post('/clientapi/category', options)
}

export const apiPatchUpdateCategory = (data) => {
    const options = {
        body: {
            ...data
        }
    }
    return Http.patch('/clientapi/category/' + data.categoryId, options)
}