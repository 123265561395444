import { Http } from '../../../utils';
export const apiGetVideoSignedUrl = (id, isLoading) => Http.get('/clientapi/video/proxy/' + id, {}, isLoading)

export const apiGetVideoPathFromUrl = (url) => {
    const options = {
        queryStringParameters: {
            url
        }
    }
    return Http.get('/clientapi/video/upload', options);
}

export const apiGetThumbnailFromVideo = (key, seek) => {
    const options = {
        queryStringParameters: {
            's3Key': key,
            'seek': seek,
            'type': 'proxy'
        }
    }
    return Http.get('/clientapi/video/thumbnail', options)
}

export const apiPostCreateVideoMetadata = (data) => {
    const options = {
        body: {
            'title': data.title,
            'description': data.description,
            'thumbnailSFW': data.getThumbnailSFW,
            'thumbnailNSFW': data.getThumbnailNSFW,
            'categories': data.categories,
            'performers': data.performers,
            'url': data.videoUrl,
            'key': data.key
        }
    }
    return Http.post('/clientapi/video', options)
}

export const apiGetThumbnailSignedUrl = (key) => {
    const options = {
        queryStringParameters: {
            'key': key,
            'type': 'thumbnail',
        }
    }
    return Http.get('/clientapi/upload', options)
}

export const apiGetCreatorVideoMetadata = (videoId) => Http.get('/clientapi/video/' + videoId);

export const apiPatchUpdateVideoMetadata = (data, videoId) => {
    const options = {
        body: {
            'title': data.title,
            'description': data.description,
            'thumbnailSFW': data.getThumbnailSFW,
            'thumbnailNSFW': data.getThumbnailNSFW,
            'categories': data.categories,
            'performers': data.performers,
            'url': data.videoUrl,
            'key': data.key
        }
    }
    return Http.patch('/clientapi/video/' + videoId, options)
}

export const apiPatchDeleteVideoStatus = (videoId) => {
    const options = {
        body: {
            'status': 'false'
        }
    }
    return Http.patch('/clientapi/video/' + videoId, options)
}